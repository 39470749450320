import React, { useState } from 'react';
import './Rightmodal.css';
import { Box, FormControl, InputBase, InputLabel, MenuItem, Select, TextField, Typography ,Button} from '@mui/material';
import Dropdown from "./Dropdown";




const Rightmodal = ({trade1,side, isOpen, onClose }) => {
    const [orderType, setOrderType] = useState('');
    const orderTypValues = ["MARKET", "LIMIT", "SL-LIMIT", "SL-M"];
    const changeOrderType = (event) => {
        setOrderType(event.target.value);
    }
    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
            <div className="modal">
                <button className="close-button1" onClick={onClose}>
                    {/* Close icon */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="black"
                        strokeWidth="2"
                        width="24"
                        height="24"
                    >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </button>
                <Box sx={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                    <div className='text34'>Symbol : <strong>{trade1.tradingsymbol}</strong></div>
                    <Typography
                        component={"label"}
                        className="label"
                        sx={{ fontSize: "14px !important", marginBottom: "10px" }}
                    >
                        Quantity
                    </Typography>
                    <TextField variant='outlined' placeholder='Enter Quantity' sx={{ fontSize: "14px !important", marginBottom: "10px" }}>

                    </TextField>
                    <Box>

                    <FormControl fullWidth sx={{
                        marginTop: "15px",

                    }}>
                        <InputLabel id="demo-simple-select-label1">Trade Type</InputLabel>
                        <Select

                            sx={{ height: "50px !important",zIndex:"200 !important" }}
                            labelId="demo-simple-select-label1"
                            id="demo-simple-select"
                            value="10"
                            label="Trade Type"

                        >
                            <MenuItem value={10}>MIS</MenuItem>
                            <MenuItem value={20}>NRML</MenuItem>
                            
                        </Select>
                    </FormControl>
                    </Box>


                    <FormControl fullWidth sx={{
                        marginTop: "25px",

                    }}>
                        <InputLabel id="demo-simple-select-label">Strategy</InputLabel>
                        <Select

                            sx={{ height: "50px !important" ,zIndex:"100 !important"}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value="70"
                            label="Strategy"

                        >
                            <MenuItem value={70}>Ten</MenuItem>
                            <MenuItem value={80}>Twenty</MenuItem>
                            <MenuItem value={90}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                    <Button className='but34'>{side}</Button>

                </Box>
            </div>
        </div>
    );
}

export default Rightmodal;
