import { toast } from "react-toastify";
import { API_URL } from "../../config";
import * as actionTypes from "../actionTypes";
import axios from "axios";
import { generatePopup } from "../../utils/popup";


export const getLiveData = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.LIVE_FEED_DATA_SUCCESS,
        payload: payload,
      });
      resolve();
    });
  };

  export const getaddsymboldata = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.ADD_SYMBOL_DATA_SOCKET,
        payload: payload,
      });
      resolve();
    });
  };


  export const getwatchlistdata = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.GET_WATCHLIST_DATA,
        payload: payload,
      });
      resolve();
    });
  };

  export const getpositiondata = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.GET_POSITION_DATA,
        payload: payload,
      });
      resolve();
    });
  };


  export const addWatchList = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
  
      axios
        .post(`${API_URL}/position/watchlistupdate/`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.ADD_WATCH_LIST_SUCCESS,
              payload: res.data,
            });
            generatePopup("success", "Symbol added succesfully.");
            resolve(res.data);
          } else {
            dispatch({
              type: actionTypes.ADD_WATCH_LIST_FAIL,
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            dispatch({
              type: actionTypes.ADD_WATCH_LIST_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };
  
  export const deleteWatchList = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
  
      axios
        .delete(`${API_URL}/position/watchlistupdate/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: payload,
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.DELETE_WATCH_LIST_DATA_SUCCESS,
              payload: res.data,
            });
            generatePopup("success", "Symbol deleted succesfully.");
            resolve(res.data);
          } else {
            dispatch({
              type: actionTypes.DELETE_WATCH_LIST_DATA_FAIL,
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            dispatch({
              type: actionTypes.DELETE_WATCH_LIST_DATA_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };





  export const userUpdateView = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
  
      axios
        .post(`${API_URL}/auth/userupateview/`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.USER_UPDATE_SUCCESS,
              payload: res.data,
            });
            generatePopup("success", "User details fetch successfully.");
            resolve(res.data);
          } else {
            dispatch({
              type: actionTypes.USER_UPDATE_FAIL,
            });
            generatePopup("error", "User details fetch error.");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            dispatch({
              type: actionTypes.USER_UPDATE_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };



  export const userUpdatePutView = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
  
      axios
        .put(`${API_URL}/auth/userupateview/`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.USER_UPDATE_PUT_SUCCESS,
              payload: res.data,
            });
            generatePopup("success", "User details updated successfully.");
            resolve(res.data);
          } else {
            dispatch({
              type: actionTypes.USER_UPDATE_PUT_FAIL,
            });
            generatePopup("error", "User details update error.");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            dispatch({
              type: actionTypes.USER_UPDATE_PUT_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };