export const zerodhaValidation = (inputValue) => {
    let isValid = {};
    if (inputValue.appkey === "") {
      isValid.appkey = "App key is required!";
    }
    if (inputValue.secretkey === "") {
      isValid.secretkey = "Secret key is required!";
    }
    if (inputValue.uid === "") {
      isValid.uid = "UID is required!";
    }
    return isValid;
  };



  export const angleValidation = (inputValue) => {
    let isValid = {};
    if (inputValue.userId === "") {
      isValid.userId = "User id is required!";
    }
    if (inputValue.password === "") {
      isValid.password = "password is required!";
    }
    if (inputValue.totp === "") {
      isValid.totp = "Totp is required!";
    }
    return isValid;
  };