import React, { useState,useRef } from 'react';
import './Mainpage.scss'; // Import the SCSS
import {motion,useInView} from 'framer-motion';

const TradingFeature = () => {
  const [selectedFeature, setSelectedFeature] = useState('portfolio');

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div>
        <h1 style={{width:'100%',alignItems:'center',display:'flex', fontSize:'26px',marginTop:'30px',justifyContent:'center'}}>Automate your trading</h1>
    <div className="trading-feature">
      {/* Left side with 4 boxes */}
      <motion.div ref={ref}
            className="features-list"
            initial={{ opacity: 0, x: -50 }}
            animate={isInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}>
        <div
          className={`feature-box ${selectedFeature === 'portfolio' ? 'active' : ''}`}
          onClick={() => handleFeatureClick('portfolio')}
        >
          <div className="icon">
            <img src="https://salmon.blr1.digitaloceanspaces.com/manage.png" alt="Manage your portfolio" />
          </div>
          <div className="content">
            <h3>Manage your portfolio</h3>
            <p>Connect all your exchange accounts and manage them from our terminal.</p>
          </div>
        </div>

        <div
          className={`feature-box ${selectedFeature === 'trailing' ? 'active' : ''}`}
          onClick={() => handleFeatureClick('trailing')}
        >
          <div className="icon">
            <img src="https://salmon.blr1.digitaloceanspaces.com/protool.png" alt="Trailing features" />
          </div>
          <div className="content">
            <h3>Trailing features</h3>
            <p>Follow price movement and automate buy/sell when needed.</p>
          </div>
        </div>

        <div
          className={`feature-box ${selectedFeature === 'bots' ? 'active' : ''}`}
          onClick={() => handleFeatureClick('bots')}
        >
          <div className="icon">
            <img src="https://salmon.blr1.digitaloceanspaces.com/manage.png" alt="Trading Bots" />
          </div>
          <div className="content">
            <h3>Trading Bots</h3>
            <p>Copy other traders easily or use AI-powered bots.</p>
          </div>
        </div>

        <div
          className={`feature-box ${selectedFeature === 'trendex' ? 'active' : ''}`}
          onClick={() => handleFeatureClick('trendex')}
        >
          <div className="icon">
            <img src="https://salmon.blr1.digitaloceanspaces.com/manage.png" alt="Trade with trendex" />
          </div>
          <div className="content">
            <h3>Trade with salmon</h3>
            <p>Maximize your profit using our AI salmon bot.</p>
          </div>
        </div>
      </motion.div>

      {/* Right side with the larger image */}
      <div className="feature-image">
        {selectedFeature === 'portfolio' && (
          <img src="https://salmon.blr1.digitaloceanspaces.com/s2.png" alt="Portfolio Feature" />
        )}
        {selectedFeature === 'trailing' && (
          <img src="https://salmon.blr1.digitaloceanspaces.com/s3.png" alt="Trailing Feature" />
        )}
        {selectedFeature === 'bots' && (
          <img src="https://salmon.blr1.digitaloceanspaces.com/s1.png" alt="Trading Bots Feature" />
        )}
        {selectedFeature === 'trendex' && (
          <img src="https://salmon.blr1.digitaloceanspaces.com/s1.png" alt="Trendex Feature" />
        )}
      </div>
    </div>
    </div>
  );
};

export default TradingFeature;
