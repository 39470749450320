import React, { useState, useRef } from 'react';
import './Mainpage.scss'
import { motion, useInView } from "framer-motion";

const Testimonial = () => {

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // Controls delay between each child animation
      },
    },
  };

  const boxVariants = {
    hidden: { opacity: 0, y: 50 }, // Start each box 50px below and transparent
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <div className="testimonial-container">
        <h1 className="header">Trusted by <span style={{ color: 'blue' }}>30k+ </span>customers and brokers in market</h1>
        <div className="testimonial-background">
          <motion.div ref={ref}
            className="testimonial-boxes"
            variants={containerVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}>
            <motion.div variants={boxVariants} className="testimonial-box" whileHover={{scale:1.05,backgroundColor:'#EDEEF4'}} transition={{type:'spring',stiffness:200}}>
              <img src="https://sam123.sgp1.digitaloceanspaces.com/agnish.jpeg" alt="Person" className="person-image" />
              <p className="review">Salmon Algo has transformed the way I trade. The algorithms are powerful, precise, and have consistently boosted my profits. I've tried several platforms, but Salmon Algo stands out.</p>
              <div className="stars">★★★★★</div>
              <p className="name">Kushal Singh</p>
              <p className="position">Software Engineer</p>
            </motion.div>
            <motion.div variants={boxVariants} className="testimonial-box" whileHover={{scale:1.05,backgroundColor:'#EDEEF4'}} transition={{type:'spring',stiffness:200}}>
              <img src="https://sam123.sgp1.digitaloceanspaces.com/amit.jpg" alt="Person" className="person-image" />
              <p className="review">I was new to algo trading, but Salmon Algo made it so simple. The platform is intuitive, and the support team is always there to help. It’s been a fantastic experience</p>
              <div className="stars">★★★★★</div>
              <p className="name">Amit Patil</p>
              <p className="position">Finance Manager</p>
            </motion.div>
            <motion.div variants={boxVariants} className="testimonial-box" whileHover={{scale:1.05,backgroundColor:'#EDEEF4'}} transition={{type:'spring',stiffness:200}}>
              <img src="https://sam123.sgp1.digitaloceanspaces.com/naman.JPG" alt="Person" className="person-image" />
              <p className="review">The flexibility and performance of Salmon Algo are unmatched. I can customize my strategies easily, and the results have exceeded my expectations. It’s my go-to trading platform now.</p>
              <div className="stars">★★★★★</div>
              <p className="name">Tanmay Oberoy</p>
              <p className="position">Software Engineer</p>
            </motion.div>
            {/* Repeat for more boxes */}
          </motion.div>
        </div>
      </div>
    </>

  )
}
export default Testimonial;



