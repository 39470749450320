import React, { useEffect, useState } from 'react';

import './Main.scss';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab, Tooltip, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {

    liveTradeDetails,
    liveOrderDetails,
    signalDetails
  } from "../redux/actions/positionAction";
import { useDispatch, useSelector } from 'react-redux';

const Scanner = () => {
    const dispatch = useDispatch();
    const signalTrades = useSelector((state) => state.Position.signalDetails);


    useEffect(() => {
      
        dispatch(signalDetails());
    
      
    }, []);
    const paginationModel = { page: 0, pageSize: 5 };

    const columns1 = [
        { field: 'symbol', headerName: 'Symbol', width: 150 },
        { field: 'product', headerName: 'Product', width: 150 },
        { field: 'prctyp', headerName: 'Price Type', width: 150 },
        { field: 'stratergy', headerName: 'Strategy', width: 150 },
        { field: 'segment', headerName: 'Segment', width: 150 },
        { field: 'price', headerName: 'Price', width: 100 },
        { field: 'side', headerName: 'Side', width: 100 },
        { field: 'trigger', headerName: 'Trigger Price', width: 120 },
        { field: 'timestamp', headerName: 'Timestamp', width: 200 },
        { field: 'strikeprice', headerName: 'Strike Price', width: 120 },
        { field: 'tag', headerName: 'Tag', width: 150 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'expiry', headerName: 'Expiry Date', width: 150 },
        { field: 'entry', headerName: 'Entry', width: 100 },
        { field: 'stoploss', headerName: 'Stop Loss', width: 100 },
        { field: 'target', headerName: 'Target', width: 100 }
      ];

      const rows1 = signalTrades.map((trade, index) => ({
        id: index,  // Use index or a unique identifier
        ...trade
      }))


    return (
        <Box className="tabs tabs12" >
            <h1 className="text444">Signal Scanner</h1>
            <p className="text445">Total 52 items sdfsdv efgev evv eveve bebebeb evew vevev ervev </p>
            <Box
                    sx={{
                        // height: "80vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
            <Paper sx={{ height: 400, width: '100%', marginTop:'30px' }}>
                            <DataGrid
                                rows={rows1}
                                columns={columns1}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ border: 0 }}
                            />
                        </Paper></Box>
        </Box>
    );
}

export default Scanner;
