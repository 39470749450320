
import React , { useEffect, useState }from 'react';
import Sidebar from './Sibebar'
import { Box } from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';

import PublicRoutes from '../PublicRoutes/PublicRoutes';
import Dashboard from './Dashboard';
import LivePrice from './LivePrice';
import Broker1 from './Broker1';
import Portfolio from './Portfolio';
import Profit from './Profit';
import Header from './Header';
import { BrokerStatus } from '../redux/actions/brokerActions';
import { useDispatch, useSelector } from 'react-redux';
import { useSocket } from '../hooks/useNewSocket';
import Scanner from './Scanner';
import Admin from './Admin';
import Contact from '../oauth/Contact';



const MainLayout = () => {

    const [opensidebar, setopensidebar] = useState(' ');
    const [brokerStatus, setBrokerStatus] = useState('');
	const [layer, setlayer] = useState('-100%');

    const LivePriceSocket = useSocket('liveprice');

    const liveFeedData = useSelector(
		(State) => State?.CommonReducer?.liveFeedData
	);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(BrokerStatus());
      }, []);

    const brokerData = useSelector((state) => state.Broker?.brokerstatus);
    useEffect(() => {
        if (brokerData['status'] == 'False'){
            setBrokerStatus('Broker Offline');
        }
        else{
            setBrokerStatus('Broker Online');
        }
      }, [brokerData]);

    const openMenu = () => {
		if (opensidebar === ' ') {
			setopensidebar('open');
			setlayer('0');
		} else {
			setopensidebar(' ');
			setlayer('-100%');
		}
	};


    return (
        <>
        <Header openMenu={openMenu} brokerStatus={brokerStatus}  layer={layer} />
        <Box sx={{ display: { xs: 'block', md: 'flex' ,background:'#F5F5F5', height:'100vh'} }}>
                  <Box component={'nav'}>
                      <Sidebar openbar={opensidebar} />
                  </Box>
                  <Box
                      component={'section'}
                      sx={{
                          padding: { xs: '1rem', md: '2rem 2rem 5rem 2rem' },
                          marginBottom: '6rem',
                          overflowY: 'scroll',
                          height:'100vh',
                          width: { xs: 'calc(100% - 0.3rem)', md: 'calc(100% - 11rem)' }
                      }}
                  >
                    <LivePrice liveFeedData={liveFeedData} />
                      {/* <Liveprice liveFeedData={liveFeedData} /> */}
                      <Box sx={{ marginTop: '2rem' }}>
                          <Routes>
                          <Route key={4} path="/" element={<Dashboard />} />
                          <Route key={4} path="/broker" element={<Broker1 brokerData={brokerData} />} />
                          <Route key={4} path="/portfolio" element={<Portfolio />} />
                          <Route key={4} path="/profit" element={<Profit />} />
                          <Route key={4} path="/scanner" element={<Scanner />} />
                          <Route key={4} path="/admin" element={<Admin />} />
                          <Route key={4} path="/contact" element={<Contact />} />
                          </Routes>
                      </Box>
                  </Box>
              </Box >
      
         </>
    );
}

export default MainLayout;
