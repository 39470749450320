import React from 'react';
import './Policy.scss';
import paymentimg from '../images/payment.jpeg' ;

const Payment = () => {
    return (
        <div >
            <div className='policydiv'>
                <h1>
                    Payment
                </h1>


            </div>
            <div className="payment-page">
                <div className="bank-details">
                    <img src="https://salmon.blr1.digitaloceanspaces.com/IBN_BIG.png" alt="ICICI Bank Logo" className="bank-logo" />
                    <h2>Bank Details for IMPS Payment</h2>
                    <div className="bank-info">
                        <p><strong>Bank Name:</strong> SBI Bank</p>
                        <p><strong>Account Number:</strong> 20524764869</p>
                        <p><strong>IFSC Code:</strong> SBIN0018245</p>
                        <p><strong>Account Name:</strong> Salmon algo software pvt Ltd</p>
                    </div>
                </div>

                <div className="qr-code-section">
                    <div className="qr-code-container">
                        <img src={paymentimg} alt="QR Code" className="qr-code" />
                        {/* <p className="qr-info">Scan to pay via UPI</p> */}
                    </div>
                </div>
            </div>

            <div className="terms-and-conditions">
                <h3>Terms and Conditions</h3>
                <p>By making a payment, you agree to the following terms and conditions:</p>
                <ul>
                    <li>All payments are non-refundable unless explicitly stated otherwise in our refund policy.</li>
                    <li>Ensure the accuracy of all payment details, including the account number and IFSC code.</li>
                    <li>Trading and investment carry risk. Salmon Algo Software is not liable for any financial losses arising from trading activities based on our services.</li>
                    <li>It is the user's responsibility to comply with all applicable laws and regulations regarding transactions and trading.</li>
                    <li>For further information, please review our full Terms of Service and Refund Policy available on our website.</li>
                </ul>
            </div>
        </div>
    );
}

export default Payment;
