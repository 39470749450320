import React, { useState,useRef } from 'react';
import { Navbar1 } from './Navbar1';
import './Mainpage.scss'
import { Box, Divider, Typography } from '@mui/material';
import Contact from './Contact';
import Faq from './Faq';
import PricingComponent from './PricingComponent';
import { motion,useInView } from 'framer-motion';

const About = () => {


    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.3, // Controls delay between each child animation
          },
        },
      };
    
      const boxVariants = {
        hidden: { opacity: 0, y: 50 }, // Start each box 50px below and transparent
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.8,
          },
        },
      };


    const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
    return (
        <div>
         


            <div className="landing-page">
                {/* SVG blobs */}
                {/* <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="blob blob-left">
                    <path fill="#D0E2FF" d="M31,-32.9C39.4,-22.6,45,-11.3,42.4,-2.6C39.8,6,28.9,12.1,20.5,27.2C12.1,42.3,6,66.4,-3.9,70.3C-13.8,74.2,-27.7,57.9,-37.4,42.8C-47.1,27.7,-52.7,13.8,-57.2,-4.6C-61.8,-23,-65.4,-46,-55.7,-56.3C-46,-66.7,-23,-64.5,-5.8,-58.6C11.3,-52.8,22.6,-43.3,31,-32.9Z" transform="translate(60 80)" />
                </svg>

                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="blob blob-right">
                    <path fill="#D0E2FF" d="M31,-32.9C39.4,-22.6,45,-11.3,42.4,-2.6C39.8,6,28.9,12.1,20.5,27.2C12.1,42.3,6,66.4,-3.9,70.3C-13.8,74.2,-27.7,57.9,-37.4,42.8C-47.1,27.7,-52.7,13.8,-57.2,-4.6C-61.8,-23,-65.4,-46,-55.7,-56.3C-46,-66.7,-23,-64.5,-5.8,-58.6C11.3,-52.8,22.6,-43.3,31,-32.9Z" transform="translate(150 100)" />
                </svg> */}

                {/* Top section with header and buttons */}
                <motion.div initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }} className="top-section">
                    <span className="badge">We build trading community</span>
                    <h1>About Us</h1>
                    <p>
                    Salmon Algo is a leading provider of algorithmic trading software, empowering traders with cutting-edge automation tools for seamless execution across global markets. 
                    </p>
                    <div className="buttons">
                        <button className="btn-demo">Join us and learn</button>

                    </div>
                </motion.div>

                {/* Image and floating avatars */}
                {/* <div className="image-section">
                    <img src="path-to-phone-image" alt="Phone" className="phone-image" />
                    <div className="avatar avatar-left">
                        <img src="https://i.pravatar.cc/80?img=1" alt="User 1" />
                    </div>
                    <div className="avatar avatar-right">
                        <img src="https://i.pravatar.cc/80?img=1" alt="User 2" />
                    </div>
                    <div className="avatar avatar-bottom">
                        <img src="https://i.pravatar.cc/80?img=1" alt="User 3" />
                    </div>
                </div> */}
            </div>

            <motion.div ref={ref} initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }} className='head12'>
                    We wrok for our values
                </motion.div>
                <motion.div ref={ref} initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }} className='p1'>At the core of everything we do are the values that define us. We believe in dedication, integrity, and excellence, 
    striving to make a positive impact every day. Our commitment to these values shapes our approach and guides our 
    journey, inspiring us to deliver meaningful results and build trust with everyone we serve.</motion.div>

            <motion.div className="box55" ref={ref}
            
            variants={containerVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}>
                
                <motion.div variants={boxVariants} className="box56">
                    <img src="https://salmon.blr1.digitaloceanspaces.com/Mission.png"></img>
                    <Typography className='text54'>Our Mission</Typography>
                    <Typography className='text59'>At Salmon Algo, our vision is to empower traders of all levels with cutting-edge technology, enabling them to navigate and thrive in financial markets with confidence and precision. We strive to lead the future of trading by blending innovation and expertise to deliver a powerful, accessible, and reliable platform.</Typography>
                </motion.div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <motion.div variants={boxVariants} className="box56">
                <img src="https://salmon.blr1.digitaloceanspaces.com/Vision.png"></img>
                    <Typography className='text54'>Our Vision</Typography>
                    <Typography className='text59'>At Salmon Algo, our mission is to empower traders of all levels with cutting-edge algorithmic trading strategies, and maximize returns. We are committed to providing a seamless, reliable, and secure platform that drives success in the financial markets.</Typography>
                </motion.div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <motion.div variants={boxVariants} className="box56">
                <img src="https://salmon.blr1.digitaloceanspaces.com/Goals.png"></img>
                    <Typography className='text54'>Our Goal</Typography>
                    <Typography className='text59'>At Salmon Algo, our Goals is to continuously improve our platform’s usability, ensuring seamless navigation and customization options that meet our users’ diverse trading needs. Leverage the latest advancements in AI and machine learning to deliver sophisticated trading algorithms and personalized analytics.</Typography>
                </motion.div>
            </motion.div>




            <Box className="boxcontainer5">
            <Box className="box57">

                    <h1>1,200+</h1>
                    <h2>Total Algo Volume</h2>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box className="box57">
                <h1>200+</h1>
                <h2>Total Happy Clients</h2>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box className="box57">
                <h1>50+</h1>
                <h2>Total Strategies</h2>
                </Box>

            </Box>

            <Faq></Faq>



            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-links">
                        <a href="#home">Home</a>
                        <a href="#about">About Us</a>
                        <a href="#services">Services</a>
                        <a href="#contact">Contact</a>
                    </div>
                    <div className="social-media">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Salmon Algo All rights reserved.</p>
                </div>
            </footer>


       

        </div>
    );
}

export default About;
