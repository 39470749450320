import React from 'react';
import './Mainpage.scss'
import { TextField, Button, Divider, Box } from '@mui/material';


const Contact = () => {
    return (
        <div className="contact-container">
            <div className="left-container">
                <div className="form-box">
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <TextField
                            label="First Name"
                            variant="outlined"

                            className="MuiTextField-root"
                        />
                        <TextField
                            label="Last Name"
                            variant="outlined"

                            className="MuiTextField-root"
                        /></Box>

                    <TextField
                        label="Email"
                        variant="outlined"

                        className="MuiTextField-root"
                    />
                    <TextField
                        label="Phone No"
                        variant="outlined"

                        className="MuiTextField-root"
                    />
                    <TextField
                        label="Additional Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        className="MuiTextField-root"
                    />
                    <Button variant="contained" color="primary" className="MuiButton-root">
                        Send Message
                    </Button>
                </div>
            </div>
            <div className="right-container">
                <h2>How can we help ?</h2>
                <div className="answer1">Please enter your question and we will find a right answer for you.</div>
                <div className="question-answer">
                    <div className="question">What services does Salmon Algo offer?</div>
                    <div className="answer">Salmon Algo offers cutting-edge algorithmic trading software solutions, enabling automated trading strategies across multiple markets. We provide tools for backtesting, real-time execution, and risk management tailored for both retail and institutional traders.</div>
                </div>
                <Divider></Divider>
                <div className="question-answer">
                    <div className="question">How does Salmon Algo enhance trading performance?</div>
                    <div className="answer">Salmon Algo optimizes trading performance by leveraging advanced algorithms, providing real-time data analysis, and executing trades with precision and speed. Our platform ensures lower latency and efficient trade execution to maximize profitability.</div>
                </div>
                <Divider></Divider>
                <div className="question-answer">
                    <div className="question">Can I customize trading strategies with Salmon Algo?</div>
                    <div className="answer">Yes, our platform allows users to build, backtest, and deploy custom trading strategies. With user-friendly tools and robust APIs, you can fine-tune your algorithms to suit your unique trading objectives.</div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
