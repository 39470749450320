import { signalDetails } from "../actions/positionAction";
import * as actionTypes from "../actionTypes";

const initState = {
    liveTradeDetails: [],
    strategyDetails:[],
    adminliveTradeDetails: [],
    liveorderdetails:[],
    signalDetails:[],
    profitDetails:[],
    userstrategyDetails:[],
}


const store = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.LIVE_PROFIT_DETAILS_INIT:
            return {
              ...state,
              error: "",
            };
        case actionTypes.LIVE_ORDER_DETAILS_INIT:
            return {
              ...state,
              error: "",
            };
        case actionTypes.LIVE_SIGNAL_DETAILS_INIT:
            return {
                ...state,
                error: "",
            };
  
      case actionTypes.GET_LIVE_TRADE_DETAILS_SUCCESS:
        return {
          ...state,
          liveTradeDetails: action.payload,
        };
        case actionTypes.ADMIN_GET_LIVE_TRADE_DETAILS_SUCCESS:
        return {
          ...state,
          adminliveTradeDetails: action.payload,
        };

    case actionTypes.GET_STRATEGY_SUCCESS:
        return {
            ...state,
            strategyDetails: action.payload,
        };
    case actionTypes.GET_USER_STRATEGY_SUCCESS:
        return {
            ...state,
            userstrategyDetails: action.payload,
        };
    case actionTypes.GET_STRATEGY_FAIL:
        return {
            ...state,
            
        };
    case actionTypes.LIVE_ORDER_DETAILS_SUCCESS:
        return {
            ...state,
            liveorderdetails: action.payload,
            error: "",
        };
    case actionTypes.LIVE_ORDER_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload,
        };


    case actionTypes.LIVE_SIGNAL_DETAILS_SUCCESS:
        return {
            ...state,
            signalDetails: action.payload,
            error: "",
        };
    case actionTypes.LIVE_SIGNAL_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case actionTypes.LIVE_PROFIT_DETAILS_SUCCESS:
        return {
            ...state,
            profitDetails: action.payload,
            error: "",
        };
    case actionTypes.LIVE_PROFIT_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default store;