import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import Marketwatch from './Marketwatch';
import AdminUser from './AdminUser';
import Usertrades from './admin/Usertrades';

const Admin = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{fontSize:"12px"}}  label="Dashboard" value="1" />
            <Tab sx={{fontSize:"12px"}}  label="Userview" value="2" />
            <Tab sx={{fontSize:"12px"}}  label="Market Watch" value="3" />
            <Tab sx={{fontSize:"12px"}}  label="User Trades" value="4" />
            <Tab sx={{fontSize:"12px"}}  label="User Close Order" value="5" />
          </TabList>
        </Box>
        <TabPanel className='tabpane' value="1"></TabPanel>
        <TabPanel className='tabpane' value="2"><AdminUser></AdminUser></TabPanel>
        <TabPanel className='tabpane' value="3"><Marketwatch></Marketwatch></TabPanel>
        <TabPanel className='tabpane' value="4"><Usertrades></Usertrades></TabPanel>
        <TabPanel className='tabpane' value="5"></TabPanel>
      </TabContext>
    </Box>
    );
}

export default Admin;
