import React from 'react';
import Mainpage from './Mainpage';
import About from './About';
import PricingComponent from './PricingComponent';
import LoginComponent from './LoginComponent';
import Signup from './Signup';
import { Navbar1 } from './Navbar1';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Policy from './Policy';
import Term from './Term';
import Refund from './Refund';
import Payment from './Payment';
import Services from './Services';
import './Mainpage.scss'

const Homelayout = () => {
    return (
        <Box>
            <div className="scrolling-text-container">
      <div className="scrolling-text">
        We are not Investment advisory. We provide algo trading service for trade automation.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We are not Investment advisory. We provide algo trading service for trade automation.
      </div>
    </div>
            <Box component={'nav'}>

                      <Navbar1  />
                  </Box>
            
            <Box component={'section'}>
                <Box>
                <Routes>
                    <Route key="home" path="/" element={<Mainpage />} />
                    <Route key="about" path="/about" element={<About />} />
                    <Route key="pricing" path="/pricing" element={<PricingComponent />} />
                    <Route key="login" path="/login" element={<LoginComponent />} />
                    <Route key={1} path="/signup" element={<Signup />} />
                    <Route key="policy" path="/policy" element={<Policy />} />
                    <Route key="term" path="/terms" element={<Term />} />
                    <Route key="refund" path="/refund" element={<Refund />} />
                    <Route key="payment" path="/payment" element={<Payment />} />
                    <Route key="service" path="/service" element={<Services />} />
                </Routes>
                </Box>
            </Box>
        </Box>
    );
}

export default Homelayout;
