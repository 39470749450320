import React, { useEffect } from 'react';
import './admin.scss';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {

    adminliveTradeDetails,

  } from "../../redux/actions/positionAction";
import { useDispatch, useSelector } from 'react-redux';

const Usertrades = () => {
    const dispatch = useDispatch();
    const liveTrades = useSelector((state) => state.Position.adminliveTradeDetails);
    useEffect(() => {
      
        dispatch(adminliveTradeDetails());
       
      
    }, []);
    const paginationModel = { page: 0, pageSize: 5 };


    const columns1 = [
        { field: 'symbol', headerName: 'Symbol', width: 150 },
        { field: 'product', headerName: 'Product', width: 150 },
        { field: 'exchange', headerName: 'Exchange', width: 150 },
        { field: 'prctyp', headerName: 'Price Type', width: 150 },
        { field: 'price', headerName: 'Price', width: 100 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'side', headerName: 'Side', width: 100 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'orderid', headerName: 'Order ID', width: 150 },
        { field: 'stratergy', headerName: 'Strategy', width: 150 },
        { field: 'remark', headerName: 'Remark', width: 200 },
        { field: 'type1', headerName: 'Type', width: 150 }
      ];

      const rows1 = liveTrades.map((trade, index) => ({
        id: index,  // Use index or a unique identifier
        ...trade
      }))
    
    return (
        <div>
            <Paper sx={{ height: 400, width: '100%', marginTop:'30px' }}>
                            <DataGrid
                                rows={rows1}
                                columns={columns1}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ border: 0 }}
                            />
                        </Paper>
        </div>
    );
}

export default Usertrades;
