

"use client";
import { NavLink, useLocation, Link } from "react-router-dom";
import './LoginComponent.scss'
import { Button, Navbar, Dropdown } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
import salmonlogo from '../images/salmonlogo.png';
export function Navbar1() {


  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');  // Redirect to the login page
  };

  const handlesignupClick = () => {
    navigate('/signup');  // Redirect to the login page
  };
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /> */}
        <img src={salmonlogo} style={{width:'70px'}}></img>

      </Navbar.Brand>

      <Navbar.Collapse>
        <Navbar.Link active>
          <Link to="/">Home</Link>
        </Navbar.Link>
        <Navbar.Link ><Link to="/about">About</Link></Navbar.Link>
        <Navbar.Link as={Link} to={"/pricing"}>Pricing</Navbar.Link>
        <Navbar.Link as={Link} to={"/payment"}>Payment</Navbar.Link>
        <Navbar.Link as={Link} to={"/service"}>It Services</Navbar.Link>
        
        <Dropdown label="Policies" inline>
          <Dropdown.Item as={Link} to="/policy">
            Privacy Policy
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/terms">
            Terms and Conditions
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/refund">
            Refund Poilicies
          </Dropdown.Item>
        </Dropdown>

      </Navbar.Collapse>

      <div className="flex md:order-2">
        <Button onClick={handleLoginClick}>Login</Button>
        <Button onClick={handlesignupClick} className="button23">Signup</Button>
        <Navbar.Toggle />
      </div>
    </Navbar>
  );
}


