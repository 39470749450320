import React from 'react';
import { Navbar1 } from './Navbar1';
import Testimonial from './Testimonial';
import Contact from './Contact';
import Landing from './Landing';
import TradingFeature from './TradingFeature';
import { Box, Typography } from '@mui/material';
import './Mainpage.scss'
import Faq from './Faq';

const Mainpage = () => {
    return (
        <div>
           
            <Landing></Landing>

            <TradingFeature></TradingFeature>
            <Box className="boxcontainer">
                <Typography className='text15'>Our Supported Brokers
                </Typography>
                <Typography className='text16'>
                    We are partner with below brokers and provide algo service for same.
                </Typography>
                <Box className="boxcontainer1">
                    <img src="https://salmon.blr1.digitaloceanspaces.com/Angel.png"></img>
                    <img src="https://salmon.blr1.digitaloceanspaces.com/Choice.png"></img>
                    <img src="https://salmon.blr1.digitaloceanspaces.com/zerodha.png"></img>
                </Box>

            </Box>
            <Testimonial></Testimonial>
            <Contact></Contact>
            <Faq></Faq>


            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-links">
                        <a href="#home">Home</a>
                        <a href="#about">About Us</a>
                        <a href="#services">Services</a>
                        <a href="#contact">Contact</a>
                    </div>
                    <div className="social-media">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} TradeboxPro LLC. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default Mainpage;
