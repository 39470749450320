import React, { useEffect } from "react";
import './Sidebar.scss'

import {
    Box,
    Button,
    Menu,
    MenuItem,
    Avatar,
    Grid,
    Divider,
    Typography,
    Tooltip,
    IconButton,
    Fade
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import salmonlogo from '../images/salmonlogo.png'

import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";

const Header = (props) => {

    const navigate = useNavigate()


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };


    const logout =() =>{
        localStorage.removeItem('token');
        navigate("/");
    }
    return (
        <>
            <Box className="header1">
                <Box className="logobox"><img src={salmonlogo} style={{width:'80px'}}></img></Box>
                <Box className="box456">
                    <Button className={`buttonheader ${props.brokerStatus === 'Broker Online' ? 'green1' : 'red1'
                        }`}>{props.brokerStatus}</Button>

                    <div className="myaccount">
                        <Button
                            id="fade-button"
                            aria-controls={open1 ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? 'true' : undefined}
                            onClick={handleClick1}
                            className="accountbt"
                        >
                            My Account
                        </Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open1}
                            onClose={handleClose1}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose1}>Profile</MenuItem>
                            <MenuItem onClick={handleClose1}>My account</MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </div>

                </Box>
                <Box className="box457">
                    <Box className="mobile-toggle-btn">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={props.openMenu}

                        >
                            {false ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </Box>

                </Box>
            </Box>

        </>
    );
}

export default Header;
