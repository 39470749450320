import React from 'react';
import { Navbar1 } from './Navbar1';
import './Policy.scss';

const Policy = () => {
    return (
        <div>

            <div className='policydiv'>
                <h1>Privacy Policy</h1>

            </div>
            <div className='privacy-policy'>
                <section>
                    <h2>1. Information We Collect</h2>
                    <p><strong>Personal Information:</strong> This may include your name, email address, phone number, billing address, and payment information, collected when you sign up, communicate with us, or make purchases.</p>
                    <p><strong>Trading Information:</strong> We may collect data about your trading activities, such as your preferred strategies, trade history, and other interactions with our platform.</p>
                    <p><strong>Technical Information:</strong> We collect technical data like IP addresses, device information, browser type, and usage details when you access our services, to improve user experience and platform security.</p>
                </section>

                <section>
                    <h2>2. How We Use Your Information</h2>
                    <p><strong>Provide Services:</strong> To facilitate and automate your trading activities, including managing your selected trading strategies and processing your trades.</p>
                    <p><strong>Enhance User Experience:</strong> We analyze usage trends to improve our offerings, user interface, and customer support.</p>
                    <p><strong>Security and Compliance:</strong> We use data to enhance security and ensure compliance with applicable laws and regulatory requirements.</p>
                    <p><strong>Marketing and Communication:</strong> To send you updates, promotional offers, and important information related to your account. You can opt out of marketing communications anytime.</p>
                </section>

                <section>
                    <h2>3. Information Sharing and Disclosure</h2>
                    <p><strong>Service Providers:</strong> We may share your information with trusted third-party vendors who assist in delivering our services, including payment processors, data storage providers, and customer support platforms. These providers are bound by confidentiality obligations.</p>
                    <p><strong>Legal Requirements:</strong> We may disclose information when required by law, such as in response to a subpoena, court order, or regulatory request.</p>
                    <p><strong>Business Transfers:</strong> If our business is sold, merged, or undergoes a similar transition, your data may be transferred to the new entity, subject to the same privacy commitments.</p>
                </section>

                <section>
                    <h2>4. Data Security</h2>
                    <p>We implement reasonable security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, no internet transmission is completely secure, so we cannot guarantee absolute security.</p>
                </section>

                <section>
                    <h2>5. Your Rights and Choices</h2>
                    <p><strong>Access and Update:</strong> You have the right to access, review, and correct your personal information held by us.</p>
                    <p><strong>Opt-Out:</strong> You can opt out of marketing emails by following the unsubscribe link provided in each communication.</p>
                    <p><strong>Data Deletion:</strong> You may request deletion of your personal data, subject to legal obligations requiring us to retain certain information.</p>
                </section>

                <section>
                    <h2>6. Cookies and Tracking Technologies</h2>
                    <p>We use cookies and similar technologies to enhance user experience, track user behavior, and understand site usage. You may adjust your browser settings to refuse cookies, but this may limit some functionalities.</p>
                </section>

                <section>
                    <h2>7. Changes to the Privacy Policy</h2>
                    <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated effective date.</p>
                </section>
            </div>
        </div>
    );
}

export default Policy;
