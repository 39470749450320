import React, { useState, useEffect } from 'react';
import './LoginComponent.scss';
import { Navbar1 } from './Navbar1';
import { authSignup } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';

const sliderData = [
    {
        image: 'https://salmon.blr1.digitaloceanspaces.com/s2.png',
        title: 'Manage your trades',
        text: 'Manage your trades with our portfolio section',
    },
    {
        image: 'https://salmon.blr1.digitaloceanspaces.com/s1.png',
        title: 'Choose your strategy',
        text: 'Customize your card with unique strategies..',
    },
    {
        image: 'https://salmon.blr1.digitaloceanspaces.com/s3.png',
        title: 'Connect with Brokers',
        text: 'Get with stock brokers for live trade.',
    },
];



const Signup = () => {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        refrecode:'salmon',
        email: '',
        phone: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const dispatch = useDispatch()
    const handleSubmit = () => {
        console.log('Form Data:', formData);
        dispatch(authSignup(formData));

        // Perform any signup logic here
    };

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderData.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(sliderInterval);
    }, []);
    return (
        <>

            <div className="login-container">
                <div className="login-form">
                    <h2>Sign Up to Salmon</h2>
                    <p>
                        If you have an account? <a href="#">Go to Login</a>
                    </p>
                    <div className='first'>
                <div className="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="firstname"
                        placeholder="Enter First Name"
                        value={formData.firstname}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="lastname"
                        placeholder="Enter Last Name"
                        value={formData.lastname}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Email address</label>
                <input
                    type="email"
                    name="email"
                    placeholder="Enter email to get started"
                    value={formData.email}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Phone</label>
                <input
                    type="tel"
                    name="phone"
                    placeholder="Enter phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleChange}
                />
            </div>

            <button className="login-button" onClick={handleSubmit}>Sign up</button>

                </div>

                <div className="login-slider">
                    <div className="slider-content">
                        <img src={sliderData[currentSlide].image} alt={sliderData[currentSlide].title} />
                        <h3>{sliderData[currentSlide].title}</h3>
                        <p>{sliderData[currentSlide].text}</p>
                        <div className="slider-indicators">
                            {sliderData.map((_, index) => (
                                <span
                                    key={index}
                                    className={`indicator ${index === currentSlide ? 'active' : ''}`}
                                    onClick={() => setCurrentSlide(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Signup;
