import React, { useEffect, useState } from 'react';

import './Main.scss';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab, Tooltip, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {

    liveTradeDetails,
    liveOrderDetails
  } from "../redux/actions/positionAction";
import { useDispatch, useSelector } from 'react-redux';
import { useSocket } from '../hooks/useNewSocket';



const Portfolio = () => {
    const liveTrades = useSelector((state) => state.Position.liveTradeDetails);
    const liveOrders = useSelector((state) => state.Position.liveorderdetails);
    const dispatch = useDispatch();
    const [position, setPosition] = useState([]);

    const addsymbol = useSocket("position");

    const positiondata = useSelector(
        (state) => state?.CommonReducer?.positiondata
      );

    useEffect(() => {
    console.log('addsymboldata from WebSocket:', positiondata); 
      if (positiondata && positiondata.length > 0) {
        setPosition(positiondata);  // Limit to 50 entries
    }
    }, [positiondata]);

    useEffect(() => {
      
          dispatch(liveTradeDetails());
          dispatch(liveOrderDetails());
        
      }, []);

      const columns1 = [
        { field: 'symbol', headerName: 'Symbol', width: 150 },
        { field: 'product', headerName: 'Product', width: 150 },
        { field: 'exchange', headerName: 'Exchange', width: 150 },
        { field: 'prctyp', headerName: 'Price Type', width: 150 },
        { field: 'price', headerName: 'Price', width: 100 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'side', headerName: 'Side', width: 100 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'orderid', headerName: 'Order ID', width: 150 },
        { field: 'stratergy', headerName: 'Strategy', width: 150 },
        { field: 'remark', headerName: 'Remark', width: 200 },
        { field: 'type1', headerName: 'Type', width: 150 }
      ];

      const rows1 = liveTrades.map((trade, index) => ({
        id: index,  // Use index or a unique identifier
        ...trade
      }))


      const columns2 = [
        { field: 'symbol', headerName: 'Symbol', width: 150 },
        { field: 'product', headerName: 'Product', width: 150 },
        { field: 'exchange', headerName: 'Exchange', width: 150 },
        { field: 'prctyp', headerName: 'Price Type', width: 150 },
        { field: 'stratergy', headerName: 'Strategy', width: 150 },
        { field: 'buyprice', headerName: 'Buy Price', width: 100 },
        { field: 'sellprice', headerName: 'Sell Price', width: 100 },
        { field: 'buyquantity', headerName: 'Buy Quantity', width: 120 },
        { field: 'sellquantity', headerName: 'Sell Quantity', width: 120 },
        { field: 'side', headerName: 'Side', width: 100 },
        { field: 'complexty', headerName: 'Complexity', width: 150 },
        { field: 'orderid', headerName: 'Order ID', width: 150 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'profit', headerName: 'Profit', width: 100 }
      ];

      const rows2 = liveOrders.map((trade, index) => ({
        id: index,  // Use index or a unique identifier
        ...trade
      }))
    const columns = [
        { field: 'exchange', headerName: 'Exchange', width: 130 },
        { field: 'side', headerName: 'Trend', width: 130 },
        { field: 'symbol', headerName: 'Symbol', width: 130 },
        { field: 'quantity', headerName: 'Quantity', width: 130 },
        { field: 'product', headerName: 'Product', width: 130 },
        { field: 'price', headerName: 'Executed Price', width: 130 },
        { field: 'liveprice', headerName: 'LTP', width: 130 },
        { field: 'Profit and Loss', headerName: 'P&L' , width: 130 },
    ];
    
    const paginationModel = { page: 0, pageSize: 5 };
    
    const rows = position.map((trade, index) => ({
        id: index,  // Use index or a unique identifier
        ...trade
      }))

    const [value, setValue] = useState("position");

    const handleChange = (e, newValue = "string") => {
        setValue(newValue);
    };
    return (
        <Box className="tabs tabs12" >
            <h1 className="text444">Portfolio</h1>
            <p className="text445">Total 52 items sdfsdv efgev evv eveve bebebeb evew vevev ervev </p>
            <TabContext value={value}>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: '20px'
                    }}
                >
                    <TabList className="main-tab maintab12" onChange={handleChange}>
                        <Tab label="Open Position" value="position" className="tab12button" />
                        <Tab label="Close Order" value="order" className="tab12button" />
                        <Tab label="TradeDetails" value="trade" className="tab12button" />

                    </TabList>

                    <Tooltip
                        arrow
                        placement="top"
                        title={
                            <Typography sx={{ fontSize: "10px" }}>Refresh </Typography>
                        }
                    >
                        <Button className="collapse-btn">
                            <RefreshIcon />
                        </Button>
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        // height: "80vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <TabPanel value="position" sx={{ padding: 0 }}>

                        <Paper sx={{ height: 400, width: '100%', marginTop:'30px' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ border: 0 }}
                            />
                        </Paper>

                    </TabPanel>

                    <TabPanel
                        value="order"
                        sx={{ padding: 0, marginTop: "1.5rem" }}
                    >
                        <Paper sx={{ height: 400, width: '100%', marginTop:'30px' }}>
                            <DataGrid
                                rows={rows2}
                                columns={columns2}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ border: 0 }}
                            />
                        </Paper>

                    </TabPanel>
                    <TabPanel
                        value="trade"
                        sx={{ padding: 0, marginTop: "1.5rem" }}
                    >
                        <Paper sx={{ height: 400, width: '100%', marginTop:'30px' }}>
                            <DataGrid
                                rows={rows1}
                                columns={columns1}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ border: 0 }}
                            />
                        </Paper>

                    </TabPanel>

                </Box>

            </TabContext>
        </Box>
    );
}

export default Portfolio;
