import * as actionTypes from "../actionTypes";

const initState = {

    liveFeedData: [],
    addsymboldata:[],
    positiondata:[],
    watchlistData: [],
    adminuserdata:{}

}


const store = (state = initState, action) => {
    switch (action.type) {
      case actionTypes.LIVE_FEED_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          liveFeedData: action?.payload,
        };
    case actionTypes.ADD_SYMBOL_DATA_SOCKET:
        console.log('Updating Redux addsymboldata with:', action.payload);
        return {
            ...state,
            
            addsymboldata: action?.payload,
        };
    case actionTypes.GET_POSITION_DATA:
        console.log('Updating Redux addsymboldata with:', action.payload);
        return {
            ...state,
            
            positiondata: action?.payload,
        };
    case actionTypes.GET_WATCHLIST_DATA:
        console.log('Updating Redux addsymboldata with:', action.payload);
        return {
            ...state,
            
            watchlistData: action?.payload,
        };
    case actionTypes.USER_UPDATE_SUCCESS:
        console.log('Updating Redux addsymboldata with:', action.payload);
        return {
            ...state,
            
            adminuserdata: action?.payload,
        };
      
  
      default:
        return state;
    }
  };
  
  export default store;
  