import { Box, TextField,Button, Grid } from '@mui/material';

import React, { useEffect, useState } from 'react';
import {
    userUpdateView,userUpdatePutView
  } from "../redux/actions/commonActions";
import { useDispatch, useSelector } from 'react-redux';

const AdminUser = () => {
    const [customerData, setCustomerData] = useState({
        customerid: '',
        brokerid: '',
        clientid: '',
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        plan_status: 'false',
        refer_code: '',
        plan_start_date: '',
        plan_end_date: '',
        is_active: 'false',
        is_loggedin: '',
        token: '',
        otp: '0',
        register_at: ''
      });

    const adminuserdata = useSelector(
        (state) => state?.CommonReducer?.adminuserdata
      );

      useEffect(() => {
        setCustomerData(adminuserdata)
        }, [adminuserdata]);
    

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');

    const handleEmailChange = (event) =>{
        setEmail(event.target.value)
    }

    const handleInputChange = (key, value) => {
        console.log(value)
        setCustomerData(prevData => ({
          ...prevData,
          [key]: value,
        }));
      };

    const userPutUpdade  = () => {
        dispatch(userUpdatePutView(customerData));
    }

    const getUpdateUSer = () => {
        let obj = {
            search: "email",
            phone : email
        }
        dispatch(userUpdateView(obj));
    }

    return (
        <div>
            <Box className="box945">
            <TextField onChange={handleEmailChange} size="small" id="outlined-search" label="Search field" type="search" />
            <Button variant="outlined" onClick={getUpdateUSer} sx={{backgroundColor:"transperant", marginLeft:"20px"}}>Search</Button>
            <Button variant="outlined" onClick={userPutUpdade} sx={{backgroundColor:"transperant", marginLeft:"20px"}}>Submit</Button>
            </Box>



            <Box sx={{ marginTop:'20px' }}>
      <Grid container spacing={2}>
        {Object.entries(customerData).map(([key, value]) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <TextField
              label={key} // Format label nicely
              value={value}
              size="small"   type="text" fullWidth
              
              onChange={(e) => handleInputChange(key, e.target.value)}
              InputLabelProps={{
                style: { fontSize: '14px' }, // Adjust label font size here
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>

    
        </div>
    );
}

export default AdminUser;
