import React from 'react';
import './Mainpage.scss'
import { motion } from 'framer-motion';

const Landing = () => {
    return (
        <>
            <div className="landing-page">
                {/* SVG blobs */}
                {/* <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="blob blob-left">
                    <path fill="#D0E2FF" d="M31,-32.9C39.4,-22.6,45,-11.3,42.4,-2.6C39.8,6,28.9,12.1,20.5,27.2C12.1,42.3,6,66.4,-3.9,70.3C-13.8,74.2,-27.7,57.9,-37.4,42.8C-47.1,27.7,-52.7,13.8,-57.2,-4.6C-61.8,-23,-65.4,-46,-55.7,-56.3C-46,-66.7,-23,-64.5,-5.8,-58.6C11.3,-52.8,22.6,-43.3,31,-32.9Z" transform="translate(60 80)" />
                </svg>

                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="blob blob-right">
                    <path fill="#D0E2FF" d="M31,-32.9C39.4,-22.6,45,-11.3,42.4,-2.6C39.8,6,28.9,12.1,20.5,27.2C12.1,42.3,6,66.4,-3.9,70.3C-13.8,74.2,-27.7,57.9,-37.4,42.8C-47.1,27.7,-52.7,13.8,-57.2,-4.6C-61.8,-23,-65.4,-46,-55.7,-56.3C-46,-66.7,-23,-64.5,-5.8,-58.6C11.3,-52.8,22.6,-43.3,31,-32.9Z" transform="translate(150 100)" />
                </svg> */}

                {/* Top section with header and buttons */}
                <motion.div className="top-section" initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }}>
                    <span className="badge">Salmon Algo Software</span>
                    <h1>Manage your trade with AI</h1>
                    <p>
                        Salmon has been created to empower strategy creators. How? By allowing them to automate their quant strategies and sell them to investors and traders the world over.
                    </p>
                    <div className="buttons">
                        <button className="btn-demo">Join Our Trading community</button>

                    </div>
                </motion.div>

                {/* Image and floating avatars */}
                {/* <div className="image-section">
                    <img src="path-to-phone-image" alt="Phone" className="phone-image" />
                    <div className="avatar avatar-left">
                        <img src="https://i.pravatar.cc/80?img=1" alt="User 1" />
                    </div>
                    <div className="avatar avatar-right">
                        <img src="https://i.pravatar.cc/80?img=1" alt="User 2" />
                    </div>
                    <div className="avatar avatar-bottom">
                        <img src="https://i.pravatar.cc/80?img=1" alt="User 3" />
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default Landing;
