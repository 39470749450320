import logo from './logo.svg';
import './App.css';
import Mainpage from './oauth/Mainpage';
import { Route, Routes } from 'react-router-dom';
import PublicRoutes from './PublicRoutes/PublicRoutes';
import MainLayout from './Inauth/MainLayout';
import React, { useEffect, useState } from 'react';
import Homelayout from './oauth/Homelayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState();

  let token = localStorage.getItem('token');

  useEffect(() => {
    if (token && token !== null && token !== 'undefined') {
      setIsLoggedIn(true);

    } else {
      setIsLoggedIn(false);

    }
  }, [token]);
  return (
    <>
      <ToastContainer position="top-right" autoClose={1500} theme="light" />
      <Routes>

        {isLoggedIn ? (
          <Route path='/*' element={<MainLayout />}></Route>
        ) : (
          <Route path='/*' element={<Homelayout />}></Route>
        )}

        {/* {PublicRoutes.map((route) => (
          <Route key={route.index} path={route.path} element={route.element} />
        ))} */}
        {/* <Route path="/*" element={<MainLayout />} /> */}
        {/* <Route path="/" element={<Mainpage />} /> */}
      </Routes>
    </>
  );
}

export default App;
