
import React, { useEffect, useState } from 'react';
import Sidebar from './Sibebar'
import './Main.scss';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Modal, Tab, TextField, Tooltip, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import {

    addUserStrategy,
    getStrategyDetails,
    getUserStrategyDetails
} from "../redux/actions/positionAction";
import { useDispatch, useSelector } from 'react-redux';
import Description from './Description';



const Dashboard = () => {
    const dispatch = useDispatch();
    const strategyDetails = useSelector((state) => state.Position.strategyDetails);
    const userstrategyDetails = useSelector((state) => state.Position.userstrategyDetails);
    const [localStrategyDetails, setLocalStrategyDetails] = useState([]);
    const [strate1,setstrat1] = useState([]);
    useEffect(() => {

        dispatch(getStrategyDetails());
        dispatch(getUserStrategyDetails());

    }, []);

    useEffect(() => {
        if (strategyDetails) {
            setLocalStrategyDetails(strategyDetails);
        }
    }, [strategyDetails]);

    useEffect(() => {
        if (userstrategyDetails) {
            setstrat1(userstrategyDetails);
        }
    }, [userstrategyDetails]);
    const [modal, setModal] = useState(false);
    const [dataset, setDataset] = useState({});
    const showMore = (trade) => {
        setModal(true);
        setDataset(trade);
    }

    const closeModal = () => {
        setModal(false);
    }

    const [value, setValue] = useState("marketplace");

    const handleChange = (e, newValue = "string") => {
        setValue(newValue);
    };

    const trades = [
        {
            title: 'Four Price Trade',
            time: '5 min',
            index: 'Nifty 500',
            match: '2 Match',
            date: '09 Feb 2024',
            description: 'Scan for Open(0) equal to Close(0) and Open(0)...'
        },
        {
            title: 'Four Price Trade',
            time: '5 min',
            index: 'Nifty 500',
            match: '2 Match',
            date: '09 Feb 2024',
            description: 'Scan for Open(0) equal to Close(0) and Open(0)...'
        },
        {
            title: 'Four Price Trade',
            time: '5 min',
            index: 'Nifty 500',
            match: '2 Match',
            date: '09 Feb 2024',
            description: 'Scan for Open(0) equal to Close(0) and Open(0)...'
        },
        {
            title: 'Four Price Trade',
            time: '5 min',
            index: 'Nifty 500',
            match: '2 Match',
            date: '09 Feb 2024',
            description: 'Scan for Open(0) equal to Close(0) and Open(0)...'
        },
        {
            title: 'Four Price Trade',
            time: '5 min',
            index: 'Nifty 500',
            match: '2 Match',
            date: '09 Feb 2024',
            description: 'Scan for Open(0) equal to Close(0) and Open(0)...'
        },
        {
            title: 'Four Price Trade',
            time: '5 min',
            index: 'Nifty 500',
            match: '2 Match',
            date: '09 Feb 2024',
            description: 'Scan for Open(0) equal to Close(0) and Open(0)...'
        },
    ];










    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [trade1, setTrade] = useState({});
    // Function to open the modal
    const openModal1 = (trade) => {
    
        setTrade(trade);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal1 = () => {
        setIsModalOpen(false);
    };

    // Handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Handle form submission or action on the button click inside modal
    const handleDeploy = () => {
        dispatch(addUserStrategy({
            id:trade1.id,
            segment:trade1.segment[0],
            trade_type : "Live",
            lot:inputValue,
            order_type:"MIS",
            trading_type:"Normal",


        }))
        console.log("Deploying with input:", inputValue);
        closeModal();
    };

    return (
        <>
            <Box className="tabs tabs12" >
                <h1 className="text444">Subscribe Our Strategy</h1>
                <p className="text445">Subscribe to our strategies to get consistent return </p>
                <TabContext value={value} className="tabs56">

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: '20px'
                        }}
                    >
                        <TabList className="main-tab maintab12" onChange={handleChange}>
                            <Tab label="Marketplace" value="marketplace" className="tab12button" />
                            <Tab label="My strategy" value="mystrategy" className="tab12button" />

                        </TabList>

                        <Tooltip
                            arrow
                            placement="top"
                            title={
                                <Typography sx={{ fontSize: "10px" }}>Refresh </Typography>
                            }
                        >
                            <Button className="collapse-btn">
                                <RefreshIcon />
                            </Button>
                        </Tooltip>
                    </Box>

                    <Box
                        sx={{
                            // height: "80vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        <TabPanel value="marketplace" sx={{ padding: 0 }}>

                            <div className="trade-container">
                                {!modal ? (
                                    <div className="trade-row">
                                        {localStrategyDetails.length > 0 ? (
                                            localStrategyDetails.map((trade, index) => (
                                                <div className="trade-card" key={index}>
                                                    <h3 className="trade-title">{trade.full_name}</h3>
                                                    <div className="trade-info">
                                                        <span className="time">{trade.tag[0]}</span>
                                                        <span className="index">{trade.tag[1]}</span>
                                                        <span className="match">{trade.tag[2]}</span>
                                                    </div>
                                                    <p className="trade-date">{trade.created_date}</p>
                                                    <p className="trade-description">{trade.stratergy_description}</p>
                                                    <div className="trade-actions">
                                                        <button className="btn take-live" onClick={() => openModal1(trade)}>Deploy</button>
                                                        <button className="btn show-results" onClick={() => showMore(trade)} >Show More</button>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>Loading strategy details... Please wait.</p>
                                        )}
                                    </div>
                                ) : (
                                    <Description dataset={dataset} closeModal={closeModal}></Description>
                                )}

                            </div>



                           
                        </TabPanel>

                        <TabPanel
                            value="mystrategy"
                            sx={{ padding: 0, marginTop: "1.5rem" }}
                        >
                            <div className="trade-container">
                                {!modal ? (
                                    <div className="trade-row">
                                        {strate1.length > 0 ? (
                                            strate1.map((trade, index) => (
                                                <div className="trade-card" key={index}>
                                                    <h3 className="trade-title">{trade.full_name}</h3>
                                                    {/* <div className="trade-info">
                                                        <span className="time">{trade.tag[0]}</span>
                                                        <span className="index">{trade.tag[1]}</span>
                                                        <span className="match">{trade.tag[2]}</span>
                                                    </div> */}
                                                    {/* <p className="trade-date">{trade.created_date}</p>
                                                    <p className="trade-description">{trade.stratergy_description}</p> */}
                                                    <p style={{fontSize:'12px',marginTop:'5px'}}>segment : {trade.segment}</p>
                                                    <p style={{fontSize:'12px',marginTop:'5px'}}>Trade Type: {trade.trade_type}</p>
                                                    <p style={{fontSize:'12px',marginTop:'5px'}}>Start Date: {trade.date}</p>
                                                    <p style={{fontSize:'12px',marginTop:'5px', marginBottom:'5px'}}>Lot Size: {trade.lot}</p>
                                                    <div className="trade-actions">
                                                        <button className="btn take-live" onClick={() => openModal1(trade)}>Undeploy</button>
                                                        {/* <button className="btn show-results" onClick={() => showMore(trade)} >Show More</button> */}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>Loading strategy details... Please wait.</p>
                                        )}
                                    </div>
                                ) : (
                                    <Description dataset={dataset} closeModal={closeModal}></Description>
                                )}

                            </div>

                        </TabPanel>

                    </Box>

                    {isModalOpen && (
                    <Modal open={isModalOpen} onClose={closeModal1}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
                        <h2>Deploy your Strategy</h2>
                        <TextField
                            label="Enter Lot Size"
                            fullWidth
                            variant="outlined"
                            value={inputValue}
                            onChange={handleInputChange}
                            sx={{ mt: 2, mb: 3 }}
                        />
                        <Box sx={{display:'flex'}}>
                        <Button variant="outlined" color="primary" onClick={handleDeploy} fullWidth>
                            Deploy
                        </Button>
                        <Button variant="contained" color="primary" onClick={closeModal1} fullWidth sx={{marginLeft:'20px'}}>
                            Close
                        </Button>
                        </Box>
                        
                    </Box>
                </Modal>)}

                </TabContext>

               
            </Box>
           
        </>
    );
}

export default Dashboard;










