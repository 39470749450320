import React from 'react';
import './Policy.scss';

const Term = () => {
    return (
        <div>
            <div className='policydiv'>
                <h1>Terms and conditions</h1>

            </div>
            <div className='privacy-policy'>
            <section>
                <h2>1. Acceptance of Terms</h2>
                <p>By using our services, you agree to be bound by these Terms and Conditions, our Privacy Policy, and any additional terms applicable to specific services.</p>
            </section>

            <section>
                <h2>2. Services Provided</h2>
                <p>Salmon Algo Software provides algorithmic trading solutions and strategies for the Indian stock market. Our services are intended for informational purposes only and do not constitute financial advice.</p>
            </section>

            <section>
                <h2>3. User Responsibilities</h2>
                <p>Users are responsible for their trading decisions and must use our platform in compliance with all applicable laws and regulations. Users must ensure their accounts and passwords are kept confidential.</p>
            </section>

            <section>
                <h2>4. Payment and Fees</h2>
                <p>All services are subject to applicable fees as outlined on our website. Payment terms, including due dates, cancellation policies, and refund eligibility, are specified at the time of purchase.</p>
            </section>

            <section>
                <h2>5. Limitation of Liability</h2>
                <p>Salmon Algo Software shall not be liable for any losses or damages arising from your use of our services, including but not limited to financial losses, data losses, or interruptions to service. Users acknowledge that trading involves risk and should assess these risks before participating.</p>
            </section>

            <section>
                <h2>6. Intellectual Property</h2>
                <p>All content, software, and materials on our platform are owned by Salmon Algo Software and protected by intellectual property laws. Unauthorized reproduction, modification, or distribution is prohibited.</p>
            </section>

            <section>
                <h2>7. Termination of Service</h2>
                <p>We reserve the right to terminate or suspend your access to our services at our discretion, with or without notice, for conduct that violates these Terms or is otherwise harmful to us or other users.</p>
            </section>

            <section>
                <h2>8. Modifications to Terms</h2>
                <p>We may update these Terms and Conditions from time to time to reflect changes to our practices or legal requirements. Continued use of our services after any updates constitutes acceptance of the revised Terms.</p>
            </section>
            </div>
            
        </div>
    );
}

export default Term;
