import { toast } from "react-toastify";
import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";


export const liveTradeDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.LIVE_ORDER_DETAILS_INIT,
      });
      axios
        .get(`${API_URL}/position/tradedetails1/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.GET_LIVE_TRADE_DETAILS_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.GET_LIVE_TRADE_DETAILS_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            dispatch({
              type: actionTypes.GET_LIVE_TRADE_DETAILS_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };


  export const adminliveTradeDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.LIVE_ORDER_DETAILS_INIT,
      });
      axios
        .get(`${API_URL}/position/admintradedetails1/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.ADMIN_GET_LIVE_TRADE_DETAILS_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.ADMIN_GET_LIVE_TRADE_DETAILS_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            dispatch({
              type: actionTypes.ADMIN_GET_LIVE_TRADE_DETAILS_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };


  export const liveOrderDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.LIVE_ORDER_DETAILS_INIT,
      });
      axios
        .get(`${API_URL}/position/liveorderdetails/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.LIVE_ORDER_DETAILS_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.LIVE_ORDER_DETAILS_FAIL,
              payload: "Data Not Found",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            dispatch({
              type: actionTypes.LIVE_ORDER_DETAILS_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };


  export const liveProfitDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.LIVE_PROFIT_DETAILS_INIT,
      });
      axios
        .get(`${API_URL}/position/profitdetails/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.LIVE_PROFIT_DETAILS_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.LIVE_PROFIT_DETAILS_FAIL,
              payload: "Data Not Found",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            dispatch({
              type: actionTypes.LIVE_PROFIT_DETAILS_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };



  export const signalDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.LIVE_SIGNAL_DETAILS_INIT,
      });
      axios
        .get(`${API_URL}/position/signaldetails/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.LIVE_SIGNAL_DETAILS_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.LIVE_SIGNAL_DETAILS_FAIL,
              payload: "Data Not Found",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            dispatch({
              type: actionTypes.LIVE_SIGNAL_DETAILS_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };




  export const getStrategyDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.GET_STRATEGY_INIT,
      });
      axios
        .get(`${API_URL}/position/getStrategyView/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.GET_STRATEGY_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.GET_STRATEGY_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            dispatch({
              type: actionTypes.GET_STRATEGY_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };



  export const getUserStrategyDetails = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      dispatch({
        type: actionTypes.GET_USER_STRATEGY_INIT,
      });
      axios
        .get(`${API_URL}/position/userAddStratergy/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.GET_USER_STRATEGY_SUCCESS,
              payload: res.data,
            });
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.GET_USER_STRATEGY_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            dispatch({
              type: actionTypes.GET_USER_STRATEGY_FAIL,
            });
            generatePopup("error", "Failed to get live trade details.");
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };




  export const addUserStrategy = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
  
      axios
        .post(`${API_URL}/position/userAddStratergy/`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actionTypes.USER_ADD_STRATEGY_SUCCESS,
              payload: res.data,
            });
            generatePopup("success", "Strategy added successfully.");
            resolve(res.data);
          } else {
            dispatch({
              type: actionTypes.USER_ADD_STRATEGY_FAIL,
            });
            generatePopup("error", "User details update error.");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            dispatch({
              type: actionTypes.USER_ADD_STRATEGY_FAIL,
            });
          } else if (error?.response?.status === 401) {
            generatePopup("error", "Token is invalid or expired.");
            localStorage.clear();
            window.location.replace("/");
          }
        });
    });
  };