import React, { useEffect, useState } from 'react';
import './Main.scss';
import Zerodha from './Broker/Zerodha';
import { zerodhaLogin } from '../redux/actions/brokerActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Angelone from './Broker/Angelone';

const Broker1 = ({brokerData}) => {

    console.log(brokerData)

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const onClose = () => {
      setOpen(false);
    }

    const onOpen = (name) => {
      if (name == "zerodha"){
      setOpen(true);}
      else if (name == "angelone"){
        setOpen1(true);
      }
    }

    const onClose1 = () => {
      setOpen1(false);
    }

    
    const brokers = [
        {
          name: 'zerodha',
          logo: 'https://salmon.blr1.digitaloceanspaces.com/zerodha.png',
          text:"Connect your Zerodha account to enjoy hassle-free automated trading. With just a few clicks, authorize access, and let our platform manage your trades efficiently through Zerodha."
        },
        {
          name: 'angelone',
          logo: 'https://salmon.blr1.digitaloceanspaces.com/Angel.png',
          text:"Link your AngelOne account to enable automated trading with our platform. Our seamless integration with AngelOne ensures secure and reliable trade execution at your fingertips."
        },
        // {
        //   name: 'broker3',
        //   logo: 'https://salmon.blr1.digitaloceanspaces.com/Choice.png',
        //   text : "Link your broker account to enable automated trading with our platform. Our seamless integration with multiple brokers ensures secure and reliable trade execution, putting control at your fingertips."
        // },
      ];
      let [searchParams, setSearchParams] = useSearchParams();
      const dispatch = useDispatch();
  const navigate = useNavigate();
      useEffect(() => {
        let apisession = searchParams.get("apisession");
        const appkey = localStorage.getItem("AppKey");
        const authCode = searchParams.get("authCode");
        const uid = localStorage.getItem("uid");
        const userId = searchParams.get("userId");
        let session = searchParams.get("request_token");
        const secretkey = localStorage.getItem("secretkey");
        const brokername = localStorage.getItem("brokername");
        const broker = localStorage.getItem("broker");
    
        
        if (session && appkey && secretkey && brokername == "zerodha") {
          let obj = {
            appkey: appkey,
            secretkey: secretkey,
            session: session,
            uid:uid,
            brokername: "zerodha",
          };
          dispatch(zerodhaLogin(obj, navigate));
        }
      }, [window, searchParams]);


      const brokersToDisplay = brokerData.length > 0
      ? brokers.filter(broker => 
          brokerData.some(data =>  data.brokername === broker.name)
        )
      : brokers;

    return (
     
        <div className="broker-container">

           {open &&<Zerodha onClose={onClose}></Zerodha>}
           {open1 &&<Angelone onClose1={onClose1} ></Angelone>}
      <h2>Connect Your Broker</h2>
      <p className="description">
      Easily connect to your preferred broker to enable seamless and automated trading with our platform. Ensure you have your broker credentials ready for a smooth setup.
      </p>
      <div className="broker-row">
        {brokersToDisplay.map((broker, index) => (
          <div className="broker-card" key={index}>
            <div className="broker-image-container">
              <img src={broker.logo} alt={broker.name} className="broker-logo" />
            </div>
            <div className="broker-info-container">
              <h3>{broker.name}</h3>
              <p className="broker-info">
                {broker.text}
              </p>
              <div className="broker-actions">
                <button className="btn connect" onClick={() => onOpen(broker.name)}>Connect Broker</button>
                <button className="btn open-account">
                  Open an Account <span>↗</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    );
}

export default Broker1;
