import React, { useState } from 'react';
import './Mainpage.scss'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';

const Faq = () => {



    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'What programming languages are supported for creating custom trading algorithms?',
      answer: 'Salmon Algo supports popular programming languages such as Python, Java, and C++. Our platform also provides APIs that integrate seamlessly with these languages, enabling users to design and implement highly customizable trading bots.',
    },
    {
      question: 'Does Salmon Algo support multi-asset trading?',
      answer: 'Yes, Salmon Algo is designed for multi-asset trading, supporting equities, forex, commodities, and cryptocurrencies. This allows traders to run diversified strategies across different asset classes using a single platform.',
    },
    {
      question: 'How does Salmon Algo ensure low-latency trade execution?',
      answer: 'Salmon Algo is built on a high-performance infrastructure with direct market access (DMA) and co-location services, minimizing delays in order routing. This ensures ultra-low latency, which is critical for high-frequency trading and other latency-sensitive strategies.',
    },
    {
      question: 'How does Salmon Algo manage risk in automated trading?',
      answer: 'Salmon Algo provides advanced risk management tools, including real-time monitoring, customizable stop-loss limits, and position sizing algorithms. These features help traders control risk exposure, protect capital, and manage portfolio volatility in live trading environments.',
    },
  ];


    return (
        <div className="faq-container">
      {/* Left Container with contact details */}
      <div className="faq-contact">
        <h2>Contact Us</h2>
        <p>Email: Salmonalgo36@gmail.com</p>
        <p>Phone: 9026156837</p>
        <p>Address: Prayagraj Uttar Pradesh</p>
        <p>Working Hours: Mon - Fri, 9:00 AM - 6:00 PM</p>

        <div className="social-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
      </div>

      {/* Right Container with FAQ questions and answers */}
      <div className="faq-questions">
      <iframe
                title="Google Map of Prayagraj"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28783.9130711882!2d81.83849699999999!3d25.4358015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399acabf29e6d6cd%3A0x20648bdb0f47a44d!2sPrayagraj%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1634769489291!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        {/* <h2>Frequently Asked Questions</h2>
        <ul>
          {faqData.map((item, index) => (
            <li key={index}>
              <div className="question" onClick={() => toggleAnswer(index)}>
                <span>{item.question}</span>
                <span className="arrow">{activeIndex === index ? '▲' : '▼'}</span>
              </div>
              {activeIndex === index && <div className="answer">{item.answer}</div>}
            </li>
          ))}
        </ul> */}
      </div>
    </div>
    );
}

export default Faq;
