
import axios from "axios";
import { generatePopup } from "../../utils/popup";
import * as actionTypes from "../actionTypes";
import { API_URL } from "../../config";


export const authSignup = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.AUTH_SIGNUP_INIT,
      });
      axios
        .post(`${API_URL}/auth/register/`, payload)
        .then((res) => {
          if (res.status == 201) {
            dispatch({
              type: actionTypes.AUTH_SIGNUP_SUCCESS,
              payload: res.data,
            });
            generatePopup("success", "User register succesfully.");
            setTimeout(() => {
              window.location.replace("/login");
            }, 5000);
            resolve(res);
          } else {
            dispatch({
              type: actionTypes.AUTH_SIGNUP_FAIL,
              payload: res.data?.message || "Failed to signup user",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log("error.response :>> ", error.response);
            dispatch({
              type: actionTypes.AUTH_SIGNUP_FAIL,
              payload:
                typeof error == "string" ? error : "User already registered!",
            });
            resolve(error);
            generatePopup("error", error?.response?.data?.email[0]);
          }
        });
    });
  };


  

  export const login = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
          type: actionTypes.LOGIN_INIT,
        });
        axios
          .post(`${API_URL}/auth/login/`, payload)
          .then((res) => {
            if (res.status === 200) {
              dispatch({
                type: actionTypes.LOGIN_SUCCESS,
                payload: res.data,
              });
             
              resolve(res);
              window.location.replace("/");
            } else {
              dispatch({
                type: actionTypes.LOGIN_FAIL,
                payload: res.data?.message || "Failed to verify otp",
              });
            }
          })
          .catch((error) => {
            
          });
      });
  }