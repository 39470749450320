export const AUTH_SIGNUP_INIT = "AUTH_SIGNUP_INIT";
export const AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS";
export const AUTH_SIGNUP_FAIL = "AUTH_SIGNUP_FAIL";


export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGIN_INIT = "LOGIN_INIT"

export const USER_ADD_STRATEGY_SUCCESS = "USER_ADD_STRATEGY_SUCCESS"
export const USER_ADD_STRATEGY_FAIL = "USER_ADD_STRATEGY_FAIL"



export const BROKER_STATUS_INIT = "BROKER_STATUS_INIT"
export const BROKER_STATUS_SUCCESS = "BROKER_STATUS_SUCCESS"
export const BROKER_STATUS_FAIL = "BROKER_STATUS_FAIL"

export const ANGEL_BROKER_INIT = "ANGEL_BROKER_INIT"
export const ANGEL_BROKER_SUCCESS = "ANGEL_BROKER_SUCCESS"
export const ANGEL_BROKER_FAIL = "ANGEL_BROKER_FAIL"

export const ZERODHA_BROKER_INIT = "ZERODHA_BROKER_INIT"
export const ZERODHA_BROKER_SUCCESS = "ZERODHA_BROKER_SUCCESS"
export const ZERODHA_BROKER_FAIL = "ZERODHA_BROKER_FAIL"


export const LIVE_FEED_DATA_SUCCESS = "LIVE_FEED_DATA_SUCCESS"


export const LIVE_ORDER_DETAILS_INIT = "LIVE_ORDER_DETAILS_INIT"
export const GET_LIVE_TRADE_DETAILS_SUCCESS = "GET_LIVE_TRADE_DETAILS_SUCCESS"
export const GET_LIVE_TRADE_DETAILS_FAIL  = "GET_LIVE_TRADE_DETAILS_FAIL"



export const LIVE_ORDER_DETAILS_SUCCESS = "LIVE_ORDER_DETAILS_SUCCESS"
export const LIVE_ORDER_DETAILS_FAIL = "LIVE_ORDER_DETAILS_FAIL"


export const LIVE_SIGNAL_DETAILS_INIT = "LIVE_SIGNAL_DETAILS_INIT"
export const LIVE_SIGNAL_DETAILS_SUCCESS = "LIVE_SIGNAL_DETAILS_SUCCESS"
export const LIVE_SIGNAL_DETAILS_FAIL  = "LIVE_SIGNAL_DETAILS_FAIL"


export const LIVE_PROFIT_DETAILS_INIT = "LIVE_PROFIT_DETAILS_INIT"
export const LIVE_PROFIT_DETAILS_SUCCESS = "LIVE_PROFIT_DETAILS_SUCCESS"
export const LIVE_PROFIT_DETAILS_FAIL  = "LIVE_PROFIT_DETAILS_FAIL"


export const ADD_SYMBOL_DATA_SOCKET = "ADD_SYMBOL_DATA_SOCKET"


export const ADD_WATCH_LIST_SUCCESS = "ADD_WATCH_LIST_SUCCESS";
export const ADD_WATCH_LIST_FAIL = "ADD_WATCH_LIST_FAIL";
export const GET_WATCH_LIST_LIVE_DATA_SUCCESS =
  "GET_WATCH_LIST_LIVE_DATA_SUCCESS";
export const GET_WATCH_LIST_LIVE_DATA_FAIL = "GET_WATCH_LIST_LIVE_DATA_FAIL";
export const DELETE_WATCH_LIST_DATA_SUCCESS = "DELETE_WATCH_LIST_DATA_SUCCESS";
export const DELETE_WATCH_LIST_DATA_FAIL = "DELETE_WATCH_LIST_DATA_FAIL";

export const GET_WATCHLIST_DATA = "GET_WATCHLIST_DATA"

export const GET_POSITION_DATA = "GET_POSITION_DATA"

export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"


export const USER_UPDATE_PUT_SUCCESS = "USER_UPDATE_PUT_SUCCESS"
export const USER_UPDATE_PUT_FAIL = "USER_UPDATE_PUT_FAIL"



export const ADMIN_GET_LIVE_TRADE_DETAILS_SUCCESS = "ADMIN_GET_LIVE_TRADE_DETAILS_SUCCESS"
export const ADMIN_GET_LIVE_TRADE_DETAILS_FAIL  = "ADMIN_GET_LIVE_TRADE_DETAILS_FAIL"


export const GET_STRATEGY_INIT = "GET_STRATEGY_INIT"
export const GET_STRATEGY_SUCCESS = "GET_STRATEGY_SUCCESS"
export const GET_STRATEGY_FAIL = "GET_STRATEGY_FAIL"



export const GET_USER_STRATEGY_INIT = "GET_USER_STRATEGY_INIT"
export const GET_USER_STRATEGY_SUCCESS = "GET_USER_STRATEGY_SUCCESS"
export const GET_USER_STRATEGY_FAIL = "GET_USER_STRATEGY_FAIL"