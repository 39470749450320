import React, { useState, useEffect } from 'react';
import './LoginComponent.scss';
import { Navbar1 } from './Navbar1';
import { login } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';

import { NavLink, Navigate, useNavigate } from "react-router-dom";

const sliderData = [
  {
    image: 'https://salmon.blr1.digitaloceanspaces.com/s2.png',
    title: 'Manage your trades',
    text: 'Manage your trades with our portfolio section',
  },
  {
    image: 'https://salmon.blr1.digitaloceanspaces.com/s1.png',
    title: 'Choose your strategy',
    text: 'Customize your card with unique strategies..',
  },
  {
    image: 'https://salmon.blr1.digitaloceanspaces.com/s3.png',
    title: 'Connect with Brokers',
    text: 'Get with stock brokers for live trade.',
  },
];

const LoginComponent = () => {

  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);


  const [formData, setFormData] = useState({

    email: '',

    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const dispatch = useDispatch()
  const handleSubmit = () => {
    
    dispatch(login(formData)).then((res) => {
      console.log("ressssssssss", res);
      if (res.status === 200) {
        localStorage.setItem("token", res?.data?.access);
        localStorage.setItem("phone", res?.data?.phone);
        navigate("/");
      } else if (res?.response?.data?.status === "False") {
        
      }
    })
    .catch((error) => {
      console.log("otp not verify :>> ", error.message);
    });

    // Perform any signup logic here
  };
  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderData.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(sliderInterval);
  }, []);

  return (
    <>

      <div className="login-container">
        <div className="login-form">
          <h2>Sign in to Salmon</h2>
          <p>
            Don’t have an account? <a href="#">Create a free account</a>
          </p>
          <div className="form-group">
            <label>Email address</label>
            <input type="email" placeholder="Enter email to get started" name="email" value={formData.email}
                        onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" placeholder="Enter your password" name="password" value={formData.password}
                        onChange={handleChange}/>
            <a href="#" className="forgot-password">Forgot password?</a>
          </div>
          <button className="login-button" onClick={handleSubmit}>Log in</button>

        </div>

        <div className="login-slider">
          <div className="slider-content">
            <img src={sliderData[currentSlide].image} alt={sliderData[currentSlide].title} />
            <h3>{sliderData[currentSlide].title}</h3>
            <p>{sliderData[currentSlide].text}</p>
            <div className="slider-indicators">
              {sliderData.map((_, index) => (
                <span
                  key={index}
                  className={`indicator ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
