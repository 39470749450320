import React, { useState, useRef,useEffect } from 'react';
import { motion, useInView } from 'framer-motion';
import './Mainpage.scss';
import './Services.scss'
import reactlogo from '../images/react.png';
import kubernetlogo from '../images/Kubernet.png';
import pythonlogo from '../images/python.png';
import flutterlogo from '../images/flutter1.png';
import fastapilogo from '../images/fastapi.png';
import figmalogo from '../images/figma.png';


const Services = () => {

    const servicesData = [
        {
            logo: kubernetlogo,
            title: 'Deployment of app with Kubernetes',
            description:
                'At Salmon Algo Software, we specialize in deploying applications with Kubernetes, ensuring scalability, resilience, and efficient resource utilization. Our team configures clusters that dynamically handle demand, providing automated failover and load balancing. We handle the entire deployment lifecycle, from containerization to orchestration, ensuring smooth integration and continuous deployment capabilities. This service empowers our clients with a high-performance infrastructure tailored for complex, high-traffic environments.',
        },
        {
            logo: reactlogo,
            title: 'Website Development',
            description:
                'Salmon Algo Software offers full-cycle website development services, creating responsive and SEO-friendly websites that resonate with your audience. Our development process focuses on security, performance, and an engaging user experience. We use the latest technologies to ensure compatibility across devices, offering a seamless interface for end-users. From initial planning to final deployment, we collaborate closely with clients to deliver customized solutions that meet their specific business needs and drive online growth.',
        },
        {
            logo: flutterlogo,
            title: 'App Development',
            description:
                'Our app development services at Salmon Algo Software cover both mobile and web applications, tailored for high performance and scalability. We build user-friendly apps that deliver seamless experiences and integrate with other business systems. With expertise in both front-end and back-end technologies, our team ensures that your app is responsive, secure, and optimized for various devices and platforms. We manage the development lifecycle end-to-end, from concept and design to launch and support.',
        },
        {
            logo: pythonlogo,
            title: 'Strategy Development',
            description:
                'Salmon Algo Software provides custom algorithmic trading strategies that are data-driven and thoroughly tested for the Indian stock market. Our team of experts uses advanced analytics and financial modeling to craft strategies that align with your trading goals and risk tolerance. We focus on maximizing returns while ensuring adaptability to market fluctuations. Our solutions empower traders with precision, helping them navigate volatile markets with confidence and achieve consistent performance.',
        },
        {
            logo: fastapilogo,
            title: 'Backend Development',
            description:
                'Our backend development service focuses on building robust, scalable, and secure systems tailored for high-demand applications. Salmon Algo Software’s team ensures efficient data handling, optimized database management, and seamless API integration. We prioritize security and data integrity, designing architectures that support scalability and resilience. Whether for an app, website, or trading platform, our backend solutions enable efficient communication between systems, ensuring reliable performance even under heavy load.',
        },
        {
            logo: figmalogo,
            title: 'UI/UX Development',
            description:
                'Our UI/UX development services at Salmon Algo Software are dedicated to creating intuitive and visually appealing interfaces that enhance user experience. We emphasize usability, accessibility, and aesthetic appeal to design interfaces that engage users effectively. By focusing on the user journey, we ensure that each interaction is seamless and satisfying. Our approach combines creativity with functionality, resulting in designs that align with your brand identity and meet the unique needs of your users.',
        },
        {
            logo: pythonlogo,
            title: 'Strategy Backtesting',
            description:
                'Salmon Algo Software offers comprehensive backtesting services to validate trading strategies before live implementation. Using historical data and sophisticated simulation tools, we analyze the effectiveness, risk, and potential returns of your strategies. This process helps traders refine their approaches, ensuring that they meet performance goals and withstand market conditions. Our backtesting service provides detailed insights, empowering traders to make informed decisions and reduce the risk of unexpected losses.',
        },
        {
            logo: kubernetlogo,
            title: 'Server Deployment',
            description:
                'We provide end-to-end server deployment solutions, from setup to ongoing maintenance, ensuring high availability, security, and scalability. Salmon Algo Software handles configuration, monitoring, and optimization to guarantee that your applications run smoothly and securely. Our deployment strategies include load balancing, automated failover, and redundancy measures, offering resilience for mission-critical applications. This service ensures that your infrastructure is robust, responsive, and able to adapt to growing demands.',
        },
    ];
    
    

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });





    const [visibleIndexes, setVisibleIndexes] = useState([]);
    
    const observer = useRef();

    useEffect(() => {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px',
            threshold: 0.1, // Trigger when 10% of the item is visible
        };

        observer.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = Number(entry.target.dataset.index);
                    setVisibleIndexes(prev => [...new Set([...prev, index])]);
                    observer.current.unobserve(entry.target); // Stop observing after it's in view
                }
            });
        }, options);

        // Observe each service card
        const serviceCards = document.querySelectorAll('.service-card');
        serviceCards.forEach(card => observer.current.observe(card));

        return () => {
            observer.current.disconnect(); // Clean up observer on component unmount
        };
    }, []);
    return (
        <div>
            <div className="landing-page">

                <motion.div initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }} className="top-section">
                    <span className="badge">We build new generation softwere</span>
                    <h1>It Services</h1>
                    <p>
                        Salmon Algo is a leading provider of algorithmic trading software, empowering traders with cutting-edge automation tools for seamless execution across global markets.
                    </p>
                    <div className="buttons">
                        <button className="btn-demo">Choose Us to Build</button>

                    </div>
                </motion.div>


            </div>




            <div className="services">
                <h1>Services we offer</h1>
                <p className="intro-text">
                    At TraderBoxPro, we revolutionize the trading experience by providing advanced tools and real-time insights. Our platform is designed to cater to traders of all levels, offering a seamless interface and powerful features.
                </p>
                <div className="services-grid">
                {servicesData.map((service, index) => (
                <motion.div 
                    key={index} 
                    data-index={index}
                    initial={{ opacity: 0, y: 50 }}  // Start from below
                    animate={visibleIndexes.includes(index) ? { opacity: 1, y: 0 } : {}} // Animate only if in view
                    transition={{ duration: 1.2 }} 
                    className="service-card"
                >
                    <img src={service.logo} alt={`${service.title} logo`} className="service-logo" />
                    <h2>{service.title}</h2>
                    <p>{service.description}</p>
                </motion.div>
            ))}
                </div>
            </div>
        </div>
    );
}

export default Services;
