import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import './Main.scss';
import { Autocomplete, Box, Drawer, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSocket } from '../hooks/useNewSocket';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    addWatchList,
    deleteWatchList
  } from "../redux/actions/commonActions";
import { Button } from 'flowbite-react';
import Rightmodal from './Rightmodal';

const Marketwatch = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [side, setSide] = useState('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

    const dispatch = useDispatch();
    const addsymbol = useSocket("watchlist");

    const watchlist = useSocket("watchlistupdate");
    const [inputValue, setInputValue] = useState(''); 
    const [datasymbol, setDatasymbol] = useState([]);
    const addsymboldata = useSelector(
        (state) => state?.CommonReducer?.addsymboldata
      );
    const watchlistData = useSelector(
    (state) => state?.CommonReducer?.watchlistData
    );
    useEffect(() => {
    console.log('addsymboldata from WebSocket:', addsymboldata); 

    if (addsymboldata && addsymboldata.length > 0) {
        setDatasymbol(addsymboldata.slice(0, 50));  // Limit to 50 entries
    }
    }, [addsymboldata]);

    const [trade, setTrade] = useState([]);


    useEffect(() => {
        
    
        if (watchlistData && watchlistData.length > 0) {
            setWatchlistD(watchlistData.slice(0, 50));  // Limit to 50 entries
        }
        }, [watchlistData]);

    const [watchlistD, setWatchlistD] = useState([]);

    const handleSendMessage = () => {
        if (inputValue) {
            addsymbol.sendMessage({

            symbol: inputValue.toUpperCase(),  // Send input value as symbol
            });}
        };



    const handleInputChange = (event) => {
        setInputValue(event.target.value); // Update input value on change
        
        };
    
        useEffect(() => {
            if (inputValue) {
                handleSendMessage();
            }
        }, [inputValue]);

        const columns = [
            { field: 'symbol', headerName: 'Symbol', width: 150 },
            { field: 'tradingsymbol', headerName: 'Trading Symbol', width: 200 },
            { field: 'strikeprice', headerName: 'Strike Price', width: 150 },
            { field: 'liveprice', headerName: 'Live Price', width: 70 },
            { field: 'O', headerName: 'Open', width: 70 },
            { field: 'H', headerName: 'High', width: 70 },
            { field: 'L', headerName: 'Low', width: 70 },
            { field: 'C', headerName: 'Close', width: 70 },
            {
              field: 'actions',
              headerName: 'Actions',
              width: 200,
              renderCell: (params) => (
                <div style={{display:"flex"}}>
                  <Button variant="contained" color="success" onClick={() => handleBuy(params.row)}>
                    B
                  </Button>
                 
                  <Button variant="outlined"  color="error" onClick={() => handleSell(params.row)}>
                    S
                  </Button>
                  <Button
                    onClick={() => handleDelete(params.row.id)}
                    color="error"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </div>
              ),
            },
          ];


          const rows = watchlistD.map((trade, index) => ({
            id: index,  // Use index or a unique identifier
            ...trade
          }))
        
          const handleBuy = (trade) => {
            setTrade(trade);
            setIsModalOpen(!isModalOpen);
            setSide("BUY");

          };
        
          const handleSell = (trade) => {
            // Implement sell functionality
            setTrade(trade);
            setIsModalOpen(!isModalOpen);
            setSide("SELL");
          };
        
          const handleDelete = (id) => {
            // Implement delete functionality
            
            console.log('Deleted:', id);
          };

    const paginationModel = { page: 0, pageSize: 5 };

    

    const handleAddSymbol = async (item) => {
        let obj = {
            exchange: item.exchange,
            expiry: item.expiry,
            // instrument: selectedAlert.instrument,
            option: item.option,
            strikeprice: item.strikeprice,
            symbol: item.symbol,
            token: item.token,
            tradingsymbol: item.tradingsymbol,
            lot:item.lot.toString(),
           
          };
          dispatch(addWatchList(obj));
    };


    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },]
    return (
        
        <Box className="tabs tabs12" >
            <Rightmodal trade1={trade} side={side} isOpen={isModalOpen} onClose={toggleModal} />
            <h1 className="text444">Watchlist</h1>
            <p className="text445">Add your stocks </p>
            <Stack spacing={2} sx={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}>
                <Autocomplete
                    id="search"
                    freeSolo
                    onInputChange={handleInputChange}
                    options={datasymbol}
                    getOptionLabel={(option) => option.tradingsymbol || ''}
                    filterOptions={(options, state) => {
                        const inputTerms = state.inputValue.trim().toLowerCase().split(" ");
                        return options.filter((option) =>
                          inputTerms.every(term => option.tradingsymbol.toLowerCase().includes(term))
                        );
                      }}
                    renderInput={(params) => <TextField {...params} label="Search"  />}
                    renderOption={(props, option) => (
                        <li {...props}>
                          <Box display="flex" alignItems="center" width="100%">
                            <Box flexGrow={1}>
                              <Typography variant="body1"><strong>Symbol:</strong> {option.symbol}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                <strong>Expiry:</strong> {option.expiry} | <strong>Lot:</strong> {option.lot} | <strong>Exchange:</strong> {option.exchange}
                              </Typography>
                            </Box>
                            <IconButton
                              size="small"
                              edge="end"
                              color="primary"
                              onClick={() => handleAddSymbol(option)}  // Replace with actual functionality
                            >
                              <AddIcon />
                            </IconButton>
                          </Box>
                        </li>
                      )}
                />
            </Stack>
            <Box
                sx={{
                    // height: "80vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <Paper sx={{ height: 400, width: '100%', marginTop: '30px' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{ pagination: { paginationModel } }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        sx={{ border: 0 }}
                    />
                </Paper>
            </Box>

        </Box>
    );
}

export default Marketwatch;
