import React from 'react';
import './Main.scss';
import { Button } from '@mui/material';


const Description = ({ dataset,closeModal }) => {

    console.log(dataset);
    return (
        <div style={{width:'100%'}}>
            <div className="custom-container">
                <Button onClick={closeModal}>Go to Back</Button>
                <div className="first1">

                    <h3>{dataset.name}</h3>
                    <p>Created At: 04-11-2024</p>
                    <p>By: Salmon Algo</p>
                </div>
                <h3>Strategy Tags</h3>
                <div className="container-wrapper">
                    <div className="tag-container">{dataset.tag[0]}</div>
                    <div className="tag-container">{dataset.tag[1]}</div>
                    <div className="tag-container">{dataset.tag[2]}</div>
                </div>
                <h3>Strategy Description</h3>
                <p>
                    {dataset.stratergy_description}
                </p>
                <h3 style={{ marginTop: '20px' }}>The Strategy would perform well in following segments:</h3>
                <div className="container-wrapper">
                    <div className="tag-container">{dataset.segment}</div>
                </div>
                <div className="second">
                    <h3>Frequently Asked Questions:</h3>
                </div>
                <h3>Capital Requirement:</h3>
                <p>
                    {dataset.captial_requirement} required for 1 lot with risk management, Please check with your
                    broker for exact margin requirement.
                </p>
                <h3>Entry and Exit times:</h3>
                <p>Entry time is {dataset.entry_time} hrs and Exit time is {dataset.exit_time} hrs.</p>
                <h3>Exit Target:</h3>
                <p>Min Target is {dataset.sl} per day.</p>
                <h3>Entry Stop loss:</h3>
                <p>Max Stop loss is 150 rs per day.</p>
                <h3>Disclaimer:</h3>
                <p>
                    All algorithms provided by SalmonAlgo are based on backtested data, but
                    there is no guarantee of their future performance Past performance is
                    not indicative of future results, We are not responsible for your profit
                    or loss. Although this strategy is fully automated, you are advised to
                    keep a track on your account monitor any deviations or errors Any
                    algorithm running in an automated system is agreed upon with the user
                    before deployment, and Trade Arth does not accept liability for any
                    losses generated by the use of these algorithms It is essential to
                    understand that trading and investing in financial markets carry
                    inherent risks, and you should carefully consider your financial
                    situation, risk tolerance, and investment goals before making any
                    decisions. Please consult your financial advisor before investing.
                </p>
            </div>
        </div>
    );
}

export default Description;
