import React from 'react';
import './Policy.scss';



const Refund = () => {
    return (
        <div>
            <div className='policydiv'>
                <h1>Refund Policy</h1>

            </div>
            <div className='privacy-policy'>
            <section>
                <h2>1. General Policy</h2>
                <p>Salmon Algo Software is committed to customer satisfaction. Our refund policy is designed to address requests fairly and promptly. Please read the policy carefully to understand the eligibility criteria for refunds.</p>
            </section>

            <section>
                <h2>2. Eligibility for Refunds</h2>
                <p>Refunds may be granted under the following circumstances:</p>
                <ul>
                    <li>Technical issues that prevent access to our platform, which are verified and not due to user error.</li>
                    <li>If a subscription is canceled within 24 hours of initial purchase, and no trading activity has been conducted using our services.</li>
                    <li>Erroneous charges resulting from billing errors on our part.</li>
                </ul>
            </section>

            <section>
                <h2>3. Non-Refundable Situations</h2>
                <p>The following circumstances do not qualify for refunds:</p>
                <ul>
                    <li>Partial months of service after the billing cycle has started.</li>
                    <li>Failure to cancel a subscription before the renewal date.</li>
                    <li>Losses incurred from trading activities based on our strategies, as trading involves risk.</li>
                    <li>Issues arising from third-party integrations or actions outside of our control.</li>
                </ul>
            </section>

            <section>
                <h2>4. Requesting a Refund</h2>
                <p>To request a refund, please contact our support team within 14 days of the transaction. Include your transaction ID, reason for the request, and any relevant documentation. Refund requests are reviewed on a case-by-case basis, and a decision will be provided within 5-7 business days.</p>
            </section>

            <section>
                <h2>5. Refund Processing</h2>
                <p>Approved refunds will be processed through the original payment method. Depending on your bank or payment provider, refunds may take up to 10 business days to reflect in your account.</p>
            </section>

            <section>
                <h2>6. Changes to the Refund Policy</h2>
                <p>We reserve the right to modify this Refund Policy at any time. Changes will be effective upon posting on our website, and continued use of our services implies acceptance of any updates.</p>
            </section>
            </div>
        </div>
    );
}

export default Refund;
