import React, { useState } from 'react';
import './PricingComponent.scss';
import { Navbar1 } from './Navbar1';
import './Mainpage.scss'

const PricingComponent = () => {
  const [isYearly, setIsYearly] = useState(false);

 

  return (
    <>
    
    <div className="pricing">
      <h2>Pay Only for What You need</h2>
      <p>
      At Salmon Algo, our mission is to empower traders of all levels with cutting-edge algorithmic trading strategies, and maximize returns. We are committed
      </p>

      <div className="pricing-toggle">
        <span className='smt'>Semi Automatic</span>
        <label className="switch">
          <input 
            type="checkbox" 
            checked={isYearly} 
            onChange={() => setIsYearly(!isYearly)} 
          />
          <span className="slider"></span>
        </label>
        <span className='smt'>Fully Automatic <span className="save">Save 20%</span></span>
      </div>

      <div className="pricing-cards">
        <div className="card basic">
          <h3>Basic</h3>
          <p>Start with basic and gain some experience</p>
          <h4>{!isYearly ? '3000' : '12500'}<span>/mo/user</span></h4>
          <button>Try Now</button>
          <ul>
            <li>Full Access to Strategies</li>
            <li>Get 1 Month subscription</li>
            <li>Trade accross all segment</li>
            <li>Support</li>
            <li>Live Trade Management</li>
          </ul>
        </div>

        <div className="card pro most-popular">
          <h3>Pro <span className="popular-tag">Most popular</span></h3>
          <p>This is mid range plan for our mid range customer</p>
          <h4>{!isYearly ? '21600' : '33750'}<span>/mo/user</span></h4>
          <button>Try Now</button>
          <ul>
          <li>Full Access to Strategies</li>
            <li>Get 3 Month subscription</li>
            <li>Trade accross all segment</li>
            <li>Support</li>
            <li>Live Trade Management</li>
          </ul>
        </div>

        <div className="card exclusive">
          <h3>Exclusive</h3>
          <p>Exclusive plan specifically of intraday trader</p>
          <h4>{!isYearly ? '40800' : '63750'}<span>/mo/user</span></h4>
          <button>Try Now</button>
          <ul>
          <li>Full Access to Strategies</li>
            <li>Get 6 Month subscription</li>
            <li>Trade accross all segment</li>
            <li>Support</li>
            <li>Live Trade Management</li>
          </ul>
        </div>

        <div className="card exclusive">
          <h3>Premium</h3>
          <p>Premium and long term plan for traders</p>
          <h4>{!isYearly ? '76800' : '120000'}<span>/mo/user</span></h4>
          <button>Try Now</button>
          <ul>
          <li>Full Access to Strategies</li>
            <li>Get 1 Year subscription</li>
            <li>Trade accross all segment</li>
            <li>Support</li>
            <li>Live Trade Management</li>
          </ul>
        </div>
      </div>


    </div>


    <div className="terms-conditions">
       

       
          <ul className="terms-list">
            <li>
              <strong>Billing Cycles</strong> - All subscriptions are billed on a recurring monthly or annual basis unless canceled.
            </li>
            <li>
              <strong>Advisor</strong> - We are not SEBI register advisory. We are algo platform.
            </li>
            <li>
              <strong>Plan Upgrades and Downgrades</strong> - You can upgrade or downgrade your plan at any time, with changes reflected in the next billing cycle.
            </li>
          </ul>
        
      </div>



    <footer className="footer">
                <div className="footer-content">
                    <div className="footer-links">
                        <a href="#home">Home</a>
                        <a href="#about">About Us</a>
                        <a href="#services">Services</a>
                        <a href="#contact">Contact</a>
                    </div>
                    <div className="social-media">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Salmon Algo All rights reserved.</p>
                </div>
            </footer>
    </>
  );
};

export default PricingComponent;
