import * as actionTypes from "../actionTypes";

const initState = {
  loading: false,
  authProfile: [],
  fundData: {},
  error: "",
};



const store = (state = initState, action) => {
    switch (action.type) {
      
      
      case actionTypes.AUTH_SIGNUP_INIT:
        return {
          ...state,
        };
      case actionTypes.AUTH_SIGNUP_SUCCESS:
        return {
          ...state,
          error: "",
        };
      case actionTypes.AUTH_SIGNUP_FAIL:
        return {
          ...state,
          error: action?.payload,
        };
        case actionTypes.LOGIN_SUCCESS:
        return {
          ...state,
          error: "",
        };
        case actionTypes.LOGIN_FAIL:
        return {
          ...state,
          error: "",
        };
        case actionTypes.LOGIN_INIT:
        return {
          ...state,
          error: "",
        };
      
      default:
        return state;
    }
  };
  
  export default store;