import React, { useEffect } from 'react';
import './Main.scss';
import { Box } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

import {

    liveProfitDetails
  } from "../redux/actions/positionAction";
import { useDispatch, useSelector } from 'react-redux';

const Profit = () => {
    const dispatch = useDispatch();
    const profitTrades = useSelector((state) => state.Position.profitDetails);

    useEffect(() => {
      
        dispatch(liveProfitDetails());
      
    }, []);


    const columns = [
        { field: 'symbol', headerName: 'Symbol', width: 150 },
        { field: 'exchange', headerName: 'Exchange', width: 150 },
        { field: 'product', headerName: 'Product', width: 150 },
        { field: 'buyquantity', headerName: 'Buy Quantity', width: 120 },
        { field: 'sellquantity', headerName: 'Sell Quantity', width: 120 },
        { field: 'buyprice', headerName: 'Buy Price', width: 100 },
        { field: 'sellprice', headerName: 'Sell Price', width: 100 },
        { field: 'side', headerName: 'Side', width: 100 },
        { field: 'profit', headerName: 'Profit', width: 100 },
        { field: 'stratergy', headerName: 'Strategy', width: 150 },
        { field: 'date', headerName: 'Date', width: 200 }
      ];
    
    const paginationModel = { page: 0, pageSize: 5 };
    
    const rows = profitTrades.map((trade, index) => ({
        id: index,  // Use index or a unique identifier
        ...trade
      }))


    return (
        <Box className="tabs tabs12" > 
        <h1 className="text444">Profit Statement</h1>
        <p className="text445">Total 52 items sdfsdv efgev evv eveve bebebeb evew vevev ervev </p>
        <Paper sx={{ height: 400, width: '100%', marginTop:'30px' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ border: 0 }}
                            />
                        </Paper>
        </Box>
    );
}

export default Profit;
